import React from 'react';
import "./footer.css";
import FooterPC from './FooterPC';
import {useMediaQuery } from 'react-responsive';
import FooterMovil from './FooterMovil';

export default function Footer() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

    return (
       <React.Fragment>
            {isTabletOrMobile?<FooterMovil/>:<FooterPC/>}
       </React.Fragment>
    )
}
