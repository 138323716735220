import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import {useMediaQuery} from 'react-responsive';

export default function Navbar() {
  const [showBasic, setShowBasic] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <MDBNavbar expand='lg' light color='light' >
      <MDBContainer fluid>
        
      <MDBNavbarToggler
        aria-controls='navbarSupportedContent'
        aria-expanded='false'
        aria-label='Toggle navigation'
        onClick={() => setShowBasic(!showBasic)}
      >
      <MDBIcon icon='bars' fas />
      </MDBNavbarToggler>
        <div style={isTabletOrMobile?{width:'240px'}:{height:'42px', width:'300px', position:'relative'}} className='container ml-0 '>
        <a href='#/' style={isTabletOrMobile?void 0:{marginLeft:'200px', position:'absolute',top:'20%'}} className="text-reset">INICIO</a>
        </div>
        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 pl-3'>
            <MDBNavbarItem >
              <MDBNavbarLink href='#/catalogo' tag='a' className='text-white'>CATALOGO</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='#/cotizaciones' tag='a' className='text-white'>COTIZACIONES</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='#/blog' tag='a' className='text-white'>BLOG</MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}