import React from 'react';
import ImgFondoDescuentos from '../../assets/FondoDescuentos.png';
import ImgPromocion1 from '../../assets/Descuento1.png';
import ImgPromocion2 from '../../assets/Descuento2.png';
import {useMediaQuery} from 'react-responsive';

export default function Promociones() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

    return (
        <React.Fragment>
            <div className='container-fluid p-0 text-white' style={{position:'relative'}}>
                <img src={ImgFondoDescuentos} className="img-fluid"
                    alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.'
                    style={isTabletOrMobile?{width:'100vw',height:'120px'}:{width:'100vw'}}
                />
                <div className='row' style={{position:'absolute',top:"10%",left:"15%"}}>
                    <div className={isTabletOrMobile?'col':'col my-auto'}
                        style={isTabletOrMobile?{marginTop:'10px',marginRight:'2vw'}:{marginRight:'10vw'}}
                    >
                        <h2 className={isTabletOrMobile?'texto-movil':void 0}>OFERTA</h2>
                        <h1 className={isTabletOrMobile?'texto-movil':void 0}>BUJIAS</h1>
                        <h1 className={isTabletOrMobile?'texto-movil':void 0}>20%</h1>
                    </div>
                    <div className='col' style={{paddingRight:'0px'}}>
                        <img src={ImgPromocion1} className="img-fluid" 
                            alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.'
                            style={isTabletOrMobile?{maxHeight:'150px'}: void 0}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
