import React, { useState } from 'react';
import FbLogo from "../../imgs/facebook.png";
import InstaLogo from "../../imgs/instagram.png";
import {useMediaQuery} from 'react-responsive';
import axios from "axios";
import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';

import {
    URL_FETCH_SUBSCRIBIR
} from "../../config";

export default function FooterPC() {
    let [correoSub,setCorreoSub] = useState('');

    let onClickSub = () => {
        if(correoSub===''){
            void 0;
        }else{
            axios.get(`${URL_FETCH_SUBSCRIBIR}/${correoSub}`);
            toggleShowExito();
        }
    };

    //-------- configuracion de modal -------//
    const [exitoModal, setExitoModal] = useState(false);
    const toggleShowExito = () => setExitoModal(!exitoModal);

    return (
        <div className="container-fluid footer">
        <div className="row">
            <div className="col-sm border-gradient">
                <h3 className="footer-vend-titulo"  ><b>Vendedores</b></h3>                    
                    <table style={{margin:'auto'}}>
                    <tbody>
                        <tr>
                            <td style={{width:'500px', height:'60px'}} >Vendedor 1:</td>
                            <td style={{width:'500px', height:'60px'}}><a href="https://api.whatsapp.com/send?phone=56976674159&amp;text=Hola%2024lemans" target="_blank">+56-976674159</a></td>
                            <span class="badge badge-danger" style={{verticalAlign:"-2.5em"}}>24lemans</span>
                        </tr>
                        <tr>
                            <td style={{width:'120px', height:'60px'}} >Vendedor 2:</td>
                            <td><a href="https://api.whatsapp.com/send?phone=56976674228&amp;text=Hola%2024lemans" target="_blank">+56-976674228</a></td>
                            <span class="badge badge-danger" style={{verticalAlign:"-2.5em"}}>24lemans</span>
                        </tr>
                        <tr>
                            <td style={{width:'120px', height:'60px'}} >Vendedor 3:</td>
                            <td><a href="https://api.whatsapp.com/send?phone=56951384245&amp;text=Hola%2024lemans" target="_blank">+56-951384245</a></td>
                            <span class="badge badge-danger" style={{verticalAlign:"-2.5em"}}>24lemans</span>

                        </tr>
                        <tr>
                            <td style={{width:'120px', height:'60px'}} >Vendedor 4:</td>
                            <td><a href="https://api.whatsapp.com/send?phone=56976674133&amp;text=Hola%20Autofix%20Express" target="_blank">+56-976674133</a></td>
                            <a href={'https://autofixexpress.cl/#/'}>
                                <span class="badge badge-danger" style={{verticalAlign:"-2.5em"}}>Autofix</span>
                            </a>
                        </tr>
                        <tr>
                            <td style={{width:'120px', height:'60px'}} >Vendedor 5:</td>
                            <td><a href="https://api.whatsapp.com/send?phone=56976674205&amp;text=Hola%20Autofix%20Express" target="_blank">+56-976674205</a></td>
                            <a href={'https://autofixexpress.cl/#/'}>
                                <span class="badge badge-danger" style={{verticalAlign:"-2.5em"}}>Autofix</span>
                            </a>
                        </tr>
                        <tr>
                            <td style={{width:'120px', height:'60px'}} >Vendedor 6:</td>
                            <td><a href="https://api.whatsapp.com/send?phone=56972587151&amp;text=Hola%20Autofix%20Express" target="_blank">+56-972587151</a></td>
                            <a href={'https://autofixexpress.cl/#/'}>
                                <span class="badge badge-danger" style={{verticalAlign:"-2.5em"}}>Autofix</span>
                            </a>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-sm text-center border-gradient">
                <h3><b>Ubicanos</b></h3>
                <br/>
                <iframe title='ubicacion' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.6622767432887!2d-70.62651258480048!3d-33.45810428077174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c561814f8249%3A0xa98b2135d8996ed9!2sAv.%20Grecia%20607%2C%20%C3%91u%C3%B1oa%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1636571550409!5m2!1ses!2scl" 
                    width='300'  height='225'  style={{border:0}} loading="lazy"></iframe>
            </div>
            <div className="col-sm border-gradient">
                <h3 ><b>Noticias</b></h3>
                <br/>
                <p>
                    24lemans chile te invita a suscribirte para recibir noticias respecto a promociones y productos.
                </p>
                <input className="form-control footer-form" type="email" placeholder="usuario@ejemplo.cl" aria-label="usuario@ejemplo.cl"
                    onChange={(event)=>setCorreoSub(event.target.value)}
                />
                <button type="button" className="btn" onClick={()=>onClickSub()} style={{backgroundColor:'#F10C0C',color:'white'}}>
                    Suscribirse
                </button>
            </div>
            <div className="col-sm">
                <h3><b>Redes Sociales</b></h3>
                <br/>
                <div className="row">
                    <div className="col col-2">
                        <a href='https://es-la.facebook.com/autofixexpresscl/'>
                            <img src={FbLogo} className="footer-logoFb" alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.'/>
                        </a>
                    </div>
                    <div className="col col-2">
                        <a href='https://www.instagram.com/autofixexpresscl/?hl=es-la'>
                        <img src={InstaLogo} className="footer-logoInsta" alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className="linea-griGrad" style={{marginTop:"50px"}}></div>
        <div className="container-fluid direccion-final">
        <p>
            Dirección: Av Grecia 607, Ñuñoa / <a href="mailto:info@24bremse.cl">info@24bremse.cl</a>
            
        </p>
        </div>
        <div style={{color:'black'}}>
            <MDBModal show={exitoModal} getOpenState={(e) => setExitoModal(e)} tabIndex='-1'>
                <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                    <MDBModalTitle>Suscripcion</MDBModalTitle>
                    <MDBBtn className='btn-close' color='none' onClick={toggleShowExito}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>Te suscribiste con exito!</MDBModalBody>

                    <MDBModalFooter>
                    <MDBBtn color='secondary' onClick={toggleShowExito}>
                        Close
                    </MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    </div>
    )
}
