export const Paginacion = {
  getPagesArray: (elements, pageSize) => {
    let noOfPages = Math.ceil(elements.length / pageSize); //Example: 23 / 5 = 4.6 = 5 pages
    let pagesArray = [];
    for (let i = 0; i < noOfPages; i++) {
      pagesArray.push({ pageIndex: i });
    }

    //Example: [ { pageIndex: 0 }, { pageIndex: 1 }, { pageIndex: 2 } ]
    return pagesArray;
  },
  getRowsByPageIndex: (elements, currentPageIndex, pageSize) => {
    /*Example
      elements = [ 20 elements ]
      currentPageIndex = 2
      pageSize = 5
      return [ 10, 11, 12, 13, 14 ]
    */

    if (!elements) return elements;

    let array = [...elements];
    let resultArray = [];
    for (
      let i = currentPageIndex * pageSize;
      i < (currentPageIndex + 1) * pageSize;
      i++
    ) {
      if (array[i]) resultArray.push(array[i]);
    }
    return resultArray;
  },
};

export const PaginacionFamilias = {
  getPagesArray: (elements, pageSize) => {
    let noOfPages = Math.ceil(Object.keys(elements).length / pageSize); //Example: 23 / 5 = 4.6 = 5 pages
    let pagesArray = [];
    for (let i = 0; i < noOfPages; i++) {
      pagesArray.push({ pageIndex: i });
    }

    //Example: [ { pageIndex: 0 }, { pageIndex: 1 }, { pageIndex: 2 } ]
    return pagesArray;
  },
  getRowsByPageIndex: (elements, currentPageIndex, pageSize) => {
    /*Example
      elements = [ 20 elements ]
      currentPageIndex = 2
      pageSize = 5
      return [ 10, 11, 12, 13, 14 ]
    */

    if (!elements) return elements;
    let resultArray = [];
    let keysFamilias = Object.keys(elements);
    for (
      let i = currentPageIndex * pageSize;
      i < (currentPageIndex + 1) * pageSize;
      i++
    ) {
      let familiaKey = keysFamilias[i]
      if (elements[familiaKey]) resultArray.push(elements[familiaKey]);
    }
    return resultArray;
  },
};

export const TablaAutosCompatibles = {
  agruparPorMarca:(elements,key) => {
    //console.log("servicio:",elements)
    let array = [...elements.data];
    //console.log("array servicio:",array)
    return array.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
};