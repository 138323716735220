import React from 'react';
import Logo from '../../assets/24BremseLogo.png';
import Ubicacion from '../../assets/Ubicacion.png';
import Horario from '../../assets/Horario.png';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { red } from '@mui/material/colors';


export default function HeaderDesktop() {
    return (
        <React.Fragment>
            <div className="container-fluid pb-2 pt-2" style={{backgroundColor:'black', color:'white'}} >
                <div className="row">
                    <div className="col-md-3"  >
                        <a href='#/'><img src={Logo} className="d-inline-block align-top"  alt="Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros." /></a>
                    </div>
                    <div className="col text-end">
                        <div  className='row g-0'>
                            <div className='col-4'>
                                <a href='#/'><PhoneAndroidIcon style={{paddingTop:'5px'}} sx={{ color: red[50] }} fontSize='large'></PhoneAndroidIcon></a>                            </div>
                            <div className='col text-start'>
                                <p style={{margin:'0px', fontSize:'0.8rem'}}>Número</p>
                                <p>+56 32 371 5717</p>
                            </div>
                        </div>
                    </div>
                    <div className="col text-end" >
                        <div className='row g-0'>
                            <div className='col-2'>
                                <a href='https://goo.gl/maps/yp9hzYR9bi4G26hs7'>
                                    <LocationOnIcon style={{paddingTop:'5px'}} sx={{ color: red[50] }}  fontSize="large"></LocationOnIcon>
                                </a>
                            </div>
                            <div className='col text-start'>
                                <p style={{margin:'0px', fontSize:'0.8rem'}}>Direccion</p>
                                <p> Av Grecia 607, Ñuñoa</p>
                            </div>
                        </div>
                    </div>
                    <div className="col text-end">
                        <div  className='row g-0'>
                            <div className='col-2'>
                                <a href='#/'><AccessTimeFilledIcon style={{paddingTop:'5px'}} sx={{ color: red[50] }} fontSize='large'></AccessTimeFilledIcon></a>
                            </div>
                            <div className='col text-start'>
                                <p style={{margin:'0px', fontSize:'0.8rem'}}>Horario</p>
                                <p>LUNES A SABADO</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
