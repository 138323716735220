import "react-multi-carousel/lib/styles.css";
import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import actions from "../../actions";
import {useMediaQuery} from 'react-responsive';
import {PaginacionFamilias} from '../../services/Services';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { CircularProgress } from "@mui/material";

function FamiliasInicio({familias ,dispatchFetchFamilias}) {
    //------- state -------//
    let [familiasGrilla,setFamiliasGrilla] = useState([]);
    let [showImages,setShowImages] = useState(false);
    //-------- paginacion --------//
    let [pagesFamilia, setPagesFamilias] = useState([]);
    let [pageSize] = useState(4);
    let [currentPageIndex, setCurrentPageIndex] = useState(0);
    //-------- media querys --------//
    const isDesktopOrLaptopSmall = useMediaQuery({ maxWidth: 1510 });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    //------- effects -------//
    useEffect(() => {
        document.title = "Inicio"
        dispatchFetchFamilias();
    }, [ dispatchFetchFamilias]);
    
    useEffect(() => {
        setShowImages(false);
        const timer = setTimeout(() => {
          setShowImages(true);
        }, 1000);
      }, [currentPageIndex]);

    useEffect(() => {
        //calcula el numro de paginas y crea un objeto pagina
        setPagesFamilias(PaginacionFamilias.getPagesArray(familias.data, pageSize));
        //paging
        let pagedArray = PaginacionFamilias.getRowsByPageIndex(
            familias.data,
            currentPageIndex,
            pageSize
        );
        //get sorted products
        setFamiliasGrilla(pagedArray);
    }, [familias.loading,pageSize,currentPageIndex])
    //------- funciones, eventos click... -------//
    let onPageIndexClicked = (clickedPageIndex) => {
        if (clickedPageIndex >= 0 && clickedPageIndex < pagesFamilia.length)
        setCurrentPageIndex(clickedPageIndex);
    };

    return (
        <React.Fragment>
            <br/>
            <div className='container text-center mx-auto'>
                <h2>POPULARES</h2>
            </div>
            {familias.loading?
                <div className="d-flex justify-content-center">
                    <CircularProgress color="warning"></CircularProgress>
                </div>
                :
                <div className='row row-cols-3 mb-8 g-0'>
                    <div className='col-1 my-auto text-end'>
                        <MDBBtn floating className='btn-danger' onClick={()=>onPageIndexClicked(currentPageIndex - 1) }>
                        <MDBIcon fas icon="caret-left" />
                        </MDBBtn>
                    </div>
                    <div className='row row-cols-2 mx-auto justify-content-center' style={{minWidth:'70vw'}}>
                    {familiasGrilla.map((elem,index)=>{
                        return(
                            <div className='col-5 bg-white shadow' key={index} style={{margin:'10px',padding:'10px'}}>
                                <div className='row row-cols-2'>
                                    <div className='col'>
                                        {showImages?
                                        <img src={elem[0].imagen2} 
                                            style={{maxHeight:'180px'}}
                                            alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.'
                                        />:
                                        <CircularProgress color="warning"></CircularProgress>}
                                    </div>
                                    <div className='col text-center my-auto'>
                                        {elem[0].id_familia}
                                        <br/>
                                        <MDBBtn className='btn-danger' onClick={()=>onPageIndexClicked(currentPageIndex - 1)}>
                                            ver mas
                                        </MDBBtn>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    </div>
                    <div className='col-1 my-auto text-start'>
                        <MDBBtn floating className='btn-danger' onClick={()=>onPageIndexClicked(currentPageIndex + 1)}>
                        <MDBIcon fas icon="caret-right" />
                        </MDBBtn>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
  familias: state.familias
});

const mapDispatchToProps = {
  dispatchFetchFamilias: actions.fetchFamilias
};

export default connect(mapStateToProps, mapDispatchToProps)(FamiliasInicio);




