import React from 'react'
import ImgDesarme from '../../assets/Desarme.png';
import ImgRepuestosNuevos from '../../assets/RepuestosNuevos.png';
import ImgImportaciones from '../../assets/Importaciones.png';
import ImgCotizaciones from '../../assets/Cotizaciones.png';
import {useMediaQuery} from 'react-responsive';

export default function Servicios() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

    return (
        <React.Fragment>
            <div className='row text-white mt-5 mb-4 g-0'>
                <div className='col' style={{marginRight:'25px'}}>
                    <a href='#/blog' className='text-white'>
                    <div className='container' style={{position:'relative', width:'40vw',marginRight:'4px'}}>
                        <img src={ImgDesarme} className="img-fluid" alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.' />
                        <h2 style={{position:'absolute' , top:"30%",left:"10%"}}
                            className={isTabletOrMobile?'texto-movil':void 0}
                        >
                            BLOG
                        </h2>
                    </div>
                    </a>
                </div>
                <div className='col' >
                    <a href='#/catalogo' className='text-white'>
                    <div className='container' style={{position:'relative', width:'40vw',marginLeft:'1px'}}>
                        <img src={ImgRepuestosNuevos} className="img-fluid" alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.' />
                        <h2 style={{position:'absolute' , top:"30%",left:"10%"}}
                            className={isTabletOrMobile?'texto-movil':void 0}
                        >
                            REPUESTOS NUEVOS
                        </h2>
                    </div>
                    </a>
                </div>
            </div>
            <div className='row text-white mb-5 g-0'>
                <div className='col' style={{marginRight:'25px'}}>
                    <a href='#/cotizaciones' className='text-white'>
                    <div className='container' style={{position:'relative', width:'40vw',marginRight:'1px'}}>
                        <img src={ImgImportaciones} className="img-fluid" alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.' />
                        <h2 style={{position:'absolute' , top:"30%",left:"10%"}}
                            className={isTabletOrMobile?'texto-movil':void 0}
                        >
                            IMPORTACIONES
                        </h2>
                    </div>
                    </a>
                </div>
                <div className='col' >
                    <a href='#/cotizaciones' className='text-white'>
                    <div className='container' style={{position:'relative', width:'40vw',marginLeft:'1px'}}>
                        <img src={ImgCotizaciones} className="img-fluid" alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.' />
                        <h2 style={{position:'absolute' , top:"30%",left:"10%"}}
                            className={isTabletOrMobile?'texto-movil':void 0}
                        >
                            COTIZACIONES
                        </h2>
                    </div>
                    </a>
                </div>
            </div>
        </React.Fragment>
    )
}