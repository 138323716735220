import React,{useState, useEffect} from 'react';
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import actions from "../../actions";
import Promo1 from "../../assets/01.png";
import Promo2 from "../../assets/02.png";
import Footer from '../Footer/Footer';
import axios from "axios";
import { useForm } from "react-hook-form";
import {useMediaQuery} from 'react-responsive';
import Modal from 'react-modal';
import {Helmet} from "react-helmet";
import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdb-react-ui-kit';

import {
    URL_POST_COTIZACION
} from "../../config";

function Cotizacion({familias, dispatchFetchFamilias}) {
    //-------- state --------/
    let [tema, setTema] = useState('');
    let [repuesto, setRepuesto] = useState(''); 
    let [marcaAuto, setMarcaAuto] = useState(''); 
    let [chasis, setChasis] = useState(''); 
    let [nombre, setNombre] = useState(''); 
    let [apellido, setApellido] = useState(''); 
    let [email, setEmail] = useState(''); 
    let [telefono, setTelefono] = useState(''); 
    let [mensaje, setMensaje] = useState(''); 
    //-------- media querys -------//
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    //------- utilidades -------//
    let location = useLocation();
    let producto = location.state;
    const marcas_selector = ["Audi", "BMW", "Mercedes benz", "Mini" , "Porsche", "Land Rover", "Volvo"];

    useEffect(() => {
        document.title = "Cotizaciones";
        dispatchFetchFamilias();
        window.scrollTo(0, 0);
    }, [ dispatchFetchFamilias]);

    const {
        register,
        handleSubmit,
        //watch,
        formState: { errors }
      } = useForm();
    
    const onSubmit = (data) => {
        openModal();
        enviarCotizacion(data);
    }; 
    //console.log(watch("email")); 

    let enviarCotizacion = (data) => {
        const chasis = data.chasis
        const nombre = data.nombre
        const email = data.email
        axios.post(`${URL_POST_COTIZACION}`, 
        {tema, repuesto, marcaAuto, chasis , nombre, apellido,  email, telefono, mensaje,producto}) 
    }; 

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
      setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
    }

    const estiloModal = {
        content: {
          top: '30%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          backgroundColor:'transparent',
          border:'transparent',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };
    
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>¿Dónde Encontrar Repuestos e Importaciones para Mercedes Benz o BMW? | 24 Bremse Chile</title>
                <meta name="description" content="¿Tienes dudas o consultas sobre nuestros productos y respuestos? Escríbenos en nuestro formulario de contacto y resolveremos tus dudas a la brevedad posible." />
            </Helmet>
        <div className="container mt-2" style={{fontSize: '0.8rem'}}>
            <MDBBreadcrumb>
            <MDBBreadcrumbItem>
            <a className='text-dark' href='#/'>Inicio</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Cotizaciones</MDBBreadcrumbItem>
            </MDBBreadcrumb>   
        </div>
        <div className="container-fluid" style={{fontSize:'0.9rem'}} >
            <h2 style={{marginLeft:"20px", marginTop:"40px"}}>Cotizaciones</h2>
            <div className="row cotizacion-box shadow">
                <div className="col">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={isTabletOrMobile?'mb-1':'form-floating mb-3'}>
                    <select className={isTabletOrMobile?'form-select form-select-sm':'form-select'} id="floatingSelect" aria-label="Floating label select example"
                        defaultValue={location.state === undefined ? '' : location.state.producto? 'Repuestos' : 'Desarme'}
                        onChange={(event) => {setTema(event.target.value)}}
                    >
                        <option>Selecciona Tema</option>
                        <option value="Repuestos">Repuestos</option>
                        <option value="Importacion">Importacion</option>
                        <option value="Desarme">Desarme</option>
                        <option value="Otro">Otro</option>
                    </select>
                    {isTabletOrMobile?void 0 :<label htmlFor="floatingSelect">Tema de cotizacion</label>}
                    </div>

                    <div className={isTabletOrMobile?'mb-1':'form-floating mb-3'}>
                        <input type="text" list="datalistOptions" id="floatingInput" placeholder="Repuesto" 
                          className={isTabletOrMobile?'form-control form-control-sm':'form-control'}
                          defaultValue={location.state === undefined ? '' 
                          : location.state.producto? location.state.producto.data[0].id_familia 
                          : location.state.auto.data[0].titulo 
                            }
                          
                          onChange={(event) => {setRepuesto(event.target.value)}}
                        />
                        {isTabletOrMobile?void 0 :<label htmlFor="floatingInput">Repuesto</label>}
                        <datalist id="datalistOptions">
                            {/*familias start */}
                            {Object.keys(familias.data).map((familia, index) =>
                                <option key={index} >
                                    {familia}
                                </option>
                            )}
                            {/*familias end */}
                        </datalist>
                    </div>
                    <div className={isTabletOrMobile?'mb-1':'form-floating mb-3'}>                    
                        {/* Imprimir marcas para el selector de filtros */}
                            <select className={isTabletOrMobile?'form-select form-select-sm':'form-select'} 
                                id="floatingSelect" aria-label="Floating label select example" placeholder=""
                                defaultValue={location.state === undefined ? '' 
                                    : location.state.producto? location.state.producto.data[0].id_marca 
                                    : location.state.auto.data[0].marca 
                                }
                                onChange={(event) => {setMarcaAuto(event.target.value)}}
                            >
                                <option>Selecciona marca de auto</option>
                                {marcas_selector.map((elem, index)=>
                                <option key={index}>{elem}</option>
                                )}                                    
                            
                            </select>
                        {isTabletOrMobile?void 0 :<label htmlFor="floatingSelect">Marca de auto</label>}
                    </div>
                    <div className={isTabletOrMobile?'mb-1':'form-floating mb-3'}>
                        <input type="text" id="floatingInput" placeholder="Numero Chasis" 
                            className={isTabletOrMobile?'form-control form-control-sm':'form-control'}
                            onChange={(event) => {setChasis(event.target.value)}}
                            {...register("chasis", {
                            required: true,
                            maxLength: 100,
                            pattern: /^[a-zA-Z0-9-_]*$/
                            })}
                        />
                            {errors?.chasis?.type === "required" && 
                            <p style={isTabletOrMobile?{fontSize:'0.75rem',color:'red'}:{fontSize:'0.7rem',color:'red'}} >
                                *Este campo es requerido
                            </p>}
                            {errors?.chasis?.type === "maxLength" && (
                                <p style={isTabletOrMobile?{fontSize:'0.75rem',color:'red'}:{fontSize:'0.7rem',color:'red'}}>
                                *Este campo no puede contener mas de 100 caracteres
                            </p>
                            )}
                            {errors?.chasis?.type === "pattern" && (
                            <p style={isTabletOrMobile?{fontSize:'0.75rem',color:'red'}:{fontSize:'0.7rem',color:'red'}} >
                                *Formato inválido
                                </p>
                            )}
                        {isTabletOrMobile?void 0 :<label htmlFor="floatingInput">Numero de Chasis</label>}
                    </div>
                    <div className="row">
                        <div className="col" style={isTabletOrMobile?{paddingRight:'1px'}:void 0}>
                            <div className={isTabletOrMobile?'mb-1':'form-floating mb-3'}>
                                <input type="text" id="floatingInput" placeholder="Nombre" 
                                    className={isTabletOrMobile?'form-control form-control-sm':'form-control'}
                                    onChange={(event) => {setNombre(event.target.value)}}
                                    {...register("nombre", {
                                    required: true,
                                    maxLength: 50,
                                    pattern: /^[A-Za-z]+$/
                                    })}
                                />
                                {errors?.nombre?.type === "required" && 
                                <p style={isTabletOrMobile?{fontSize:'0.75rem',color:'red'}:{fontSize:'0.7rem',color:'red'}} >

                                    *Este campo es requerido
                                </p>}
                                {errors?.nombre?.type === "maxLength" && (
                                <p style={isTabletOrMobile?{fontSize:'0.75rem',color:'red'}:{fontSize:'0.7rem',color:'red'}} >

                                    *Este campo no puede contener mas de 50 caracteres
                                </p>
                                )}
                                {errors?.nombre?.type === "pattern" && (
                                <p style={isTabletOrMobile?{fontSize:'0.75rem',color:'red'}:{fontSize:'0.7rem',color:'red'}} >

                                    *Nombre inválido
                                </p>
                                )}
                                {isTabletOrMobile?void 0 :<label htmlFor="floatingInput">Nombre</label>}
                            </div>
                        </div>
                        <div className="col" style={isTabletOrMobile?{paddingLeft:'1px'}:void 0}>
                            <div className={isTabletOrMobile?'mb-1':'form-floating mb-3'}>
                                <input type="text" id="floatingInput" placeholder="Apellido" 
                                    className={isTabletOrMobile?'form-control form-control-sm':'form-control'}
                                    onChange={(event) => {setApellido(event.target.value)}}
                                />
                                {isTabletOrMobile?void 0 :<label htmlFor="floatingInput">Apellido</label>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col" style={isTabletOrMobile?{paddingRight:'1px'}:void 0}>
                            <div className={isTabletOrMobile?'mb-1':'form-floating mb-3'}>
                                <input type="email" id="floatingInput" placeholder="Correo" 
                                    className={isTabletOrMobile?'form-control form-control-sm':'form-control'}
                                    onChange={(event) => {setEmail(event.target.value)}}
                                    {...register("email", {
                                    required: true,
                                    maxLength: 50,
                                    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                                    })}
                                />
                                {errors?.email?.type === "required" && 
                                <p style={isTabletOrMobile?{fontSize:'0.75rem',color:'red'}:{fontSize:'0.7rem',color:'red'}} >

                                    *Este campo es requerido
                                </p>}
                                {errors?.email?.type === "maxLength" && (
                                <p style={isTabletOrMobile?{fontSize:'0.75rem',color:'red'}:{fontSize:'0.7rem',color:'red'}} >
                                    *Este campo no puede contener mas de 50 caracteres
                                </p>
                                )}
                                {errors?.email?.type === "pattern" && (
                                <p style={isTabletOrMobile?{fontSize:'0.75rem',color:'red'}:{fontSize:'0.7rem',color:'red'}} >

                                    *Correo inválido
                                </p>
                                )}
                                {isTabletOrMobile?void 0 :<label htmlFor="floatingInput">Correo Electronico</label>}
                            </div>
                        </div>
                        <div className="col" style={isTabletOrMobile?{paddingLeft:'1px'}:void 0}>
                            <div className={isTabletOrMobile?'mb-1':'form-floating mb-3'}>
                                <input type="text" id="floatingInput" placeholder="Num telef" 
                                    className={isTabletOrMobile?'form-control form-control-sm':'form-control'}
                                    onChange={(event) => {setTelefono(event.target.value)}}
                                />
                                {isTabletOrMobile?void 0 :<label htmlFor="floatingInput">Numero de Telefono</label>}
                            </div>
                        </div>
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Mensaje</label>
                        <textarea className={isTabletOrMobile?'form-control form-control-sm':'form-control'} 
                            id="exampleFormControlTextarea1" rows="3" 
                            onChange={(event) => {setMensaje(event.target.value)}}
                        />
                    </div>
                    
                    <button type="submit" className={isTabletOrMobile?'btn btn-sm btn-danger':'btn btn-danger'}>
                      Enviar Cotizacion
                    </button>
                    {/* MODAL */}
                        <div>
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                            style={estiloModal}
                            >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Cotizacion Enviada con Exito!</h5>
                                    <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
                                </div>
                                <div className="modal-body">
                                    <p>Te responderemos al correo o telefono que indicaste, más pronto posible!!</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" style={{margin:'auto'}} 
                                        onClick={closeModal}
                                    >
                                        Ok!
                                    </button>
                                </div>
                                </div>
                            </div>
                            </Modal>
                        </div>
                    </form>
                </div>
                <div className="col">
                    <div style={{marginBottom:"25px", textAlign:"center"}}> 
                    {location.state === undefined ?
                        <p style={isTabletOrMobile? {fontSize:'0.7rem'}:void 0}
                            className={isTabletOrMobile?'text-justify':void 0}
                        >
                        Somos una empresa dedicada a satisfacer las necesidades de nuestros clientes, 
                        ofreciendo la mejor atención, garantizado lo mejor en calidad en nuestra amplia 
                        gama de repuestos, confianza, responsabilidad en entregas a los mejores precios del mercado.
                        </p>
                        : location.state.producto?
                        <div>
                            <p>Cotizando Repuesto: {location.state.producto.data[0].parte} </p>
                            <img src={location.state.producto.data[0].imagen2}  style={{height:"135px", width:"180px", margin:"auto"}} alt='¿Tienes dudas o consultas sobre nuestros productos y respuestos? Escríbenos en nuestro formulario de contacto y resolveremos tus dudas a la brevedad posible.'/>
                        </div>
                        :
                        <div>
                            <p>Cotizando auto en desarme: {location.state.auto.data[0].titulo} </p>
                            <img src={location.state.auto.data[0].imagen_principal}  style={{height:"135px", width:"180px", margin:"auto"}} alt='¿Tienes dudas o consultas sobre nuestros productos y respuestos? Escríbenos en nuestro formulario de contacto y resolveremos tus dudas a la brevedad posible.'/>
                        </div>
                    }
                    </div>
                    <div className={isTabletOrMobile?'linea-gradiente-cotizacion-celular':'linea-gradiente-cotizacion'}></div>
                    <div id="carouselExampleIndicators" className="carousel slide carousel-dark" data-bs-ride="carousel" style={{marginTop:"25px"}}>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                            <img src={Promo1} className="d-block " alt='¿Tienes dudas o consultas sobre nuestros productos y respuestos? Escríbenos en nuestro formulario de contacto y resolveremos tus dudas a la brevedad posible.' style={{width:'70%', margin:"auto"}} />
                            </div>
                            <div className="carousel-item">
                            <img src={Promo2} className="d-block " alt='¿Tienes dudas o consultas sobre nuestros productos y respuestos? Escríbenos en nuestro formulario de contacto y resolveremos tus dudas a la brevedad posible.' style={{width:'70%', margin:"auto"}} />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    familias: state.familias,
});
  
const mapDispatchToProps = {
    dispatchFetchFamilias: actions.fetchFamilias,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cotizacion);
