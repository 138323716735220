import * as actionTypes from "../constants/action-types";
import axios from "axios";

import {
  URL_FETCH_FAMILIAS
} from "../config";

export const fetchFamilias = () => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_FAMILIAS});
  try
  {
    var response = await axios.get(`${URL_FETCH_FAMILIAS}`);

    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_FAMILIAS_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_FAMILIAS_ERROR,
      payload: err
    });
  }
};
