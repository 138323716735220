import React from 'react';
import Logo from '../../assets/24BremseLogo.png';
import Ubicacion from '../../assets/Ubicacion.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { red } from '@mui/material/colors';


export default function HeaderMovil() {
    return (
        <React.Fragment>
            <div className="container-fluid pt-1 pb-1" style={{backgroundColor:'black'}} >
                <div className="row row-cols-3 g-0">
                    <div className="col text-center" style={{paddingTop:'10px',paddingLeft:'0px',paddingRight:'0px'}}>
                        <img src={Logo} className="image-fluid" alt="Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros." style={{width:'100px'}}/>
                    </div>
                    <div className="col-2 text-center align-self-center">
                        <a href='https://www.google.com/maps/place/Autofix+Express/@-33.4185314,-70.6750342,15z/data=!4m5!3m4!1s0x0:0xa6332e7176fb5f18!8m2!3d-33.4185513!4d-70.6750529'>
                        <LocationOnIcon fontSize='large' sx={{ color: red[50] }}></LocationOnIcon>
                        </a>
                    </div>
                    <div className='position-relative text-white'>
                        <div className='position-relative'>
                            <p style={{margin:'0px', paddingTop:'10px', fontSize:'0.5rem'}}>Direccion</p>
                            <p style={{fontSize:'0.6rem'}}> LONGITUDINAL TRES 621, INDEPENDENCIA</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
