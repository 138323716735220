import React from 'react';
import SliderInicio from '../Sliders/SliderInicio';
import FamiliasInicio from './FamiliasInicio';
import Servicios from './Servicios';
import Promociones from './Promociones';
import Footer from '../Footer/Footer';
import BannerMarcas from "../../assets/BannerMarcas.png";
import SliderProductos from '../Sliders/SliderProductos';
import {useMediaQuery} from 'react-responsive';
import SliderPosts from '../Sliders/SliderPosts';
import {Helmet} from "react-helmet";
import "./inicio.css";

export default function Inicio() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>24 Bremse Chile: Venta y Distribución de Repuestos para autos de gama alta</title>
                <meta name="description" content="Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros." />
            </Helmet>
            <SliderInicio/>
            {isTabletOrMobile?
                <SliderProductos />
                :
                <FamiliasInicio/>
            }
            <img src={BannerMarcas} 
                className="img-fluid" 
                alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.'
                style={{width:'100vw'}}
            />
            <Servicios/>
            <Promociones/>
            <SliderPosts/>
            <Footer/>
        </React.Fragment>

    )
}
