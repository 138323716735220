import React from 'react';
import {useMediaQuery} from 'react-responsive';
import CatalogoPC from './CatalogoPC';
import CatalogoMovil from './CatalogoMovil';
import {Helmet} from "react-helmet";
import "../../styles/home.css";

export default function Header() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

    return (
        <React.Fragment>    
            <Helmet>
                <meta charSet="utf-8" />
                <title>Catalogo de Repuestos y Componentes para Autos de Lujo | 24 Bremse Chile</title>
                <meta name="description" content="Encuentra componentes, repuestos originales y alternativos para autos de alta gama, trabajamos con marcas de calidad como Zimmerman, AL-KO, ALCO, HEPU, ElRing" />
            </Helmet>
            {isTabletOrMobile?
                <CatalogoMovil/>
                :
                <CatalogoPC/>
            }
        </React.Fragment>
    )
}