import React, { useEffect,useState } from 'react';
import actions from "../../actions";
import { connect } from "react-redux";
import {Paginacion} from '../../services/Services';
import { Link } from "react-router-dom";
import Footer from '../Footer/Footer';
import { 
    MDBAccordion, MDBBreadcrumb,MDBBreadcrumbItem,
    MDBAccordionItem,MDBBtn,
    MDBListGroup,MDBListGroupItem,
    MDBInputGroup,MDBInputGroupText,MDBInputGroupElement,
    MDBIcon,MDBBadge,MDBRipple
} from 'mdb-react-ui-kit';

function BlogPC({posts,dispatchPosts}) {
    //----------- paginacion de tabla ----------------//
    let [pageSize] = useState(4);
    let [pages, setPages] = useState([]);
    let [currentPageIndex, setCurrentPageIndex] = useState(0);
    let [postsGrilla, setPostGrilla] = useState([]);
    //------------VARIABLES DE FILTROS-------------------//
    let [categoriaBuscada, setCategoriaBuscada] = useState("");
    let [etiquetasBuscadas, setEtiquetasBuscadas] = useState("");
    let [tituloBuscado, setTituloBuscado] = useState("");
    let [fechaBuscada, setFechaBuscada] = useState("");
    let [textoBuscado,setTextoBuscado] = useState('');
    //------------VARIABLES DE FILTROS fin -------------------//

    //------------LOGICA DE FILTROS!!-------------------//
    let postFiltCategoria = posts.data.filter(post => 
        post.tema.indexOf(categoriaBuscada) >= 0
    );
    let postFiltEtiquetas = postFiltCategoria.filter(post => {
        //post.texto.indexOf(etiquetasBuscadas.toUpperCase()) >= 0
        var textoTotal = JSON.stringify(post)
        var count = -1
        for(let i=0; i<etiquetasBuscadas.split(' ').length;i++){
            if(textoTotal.indexOf(etiquetasBuscadas.split(' ')[i]) >= 0){
                count ++;
            }
        }   
        if(count >= 0 && count===etiquetasBuscadas.split(' ').length-1){
            return true
        }else {
            return false
        }
    });
    let postFiltUltimos = postFiltEtiquetas.filter(post => 
        post.titulo.indexOf(tituloBuscado) >= 0
    );
    let postFiltFecha = postFiltUltimos.filter(post => 
        post.fecha.indexOf(fechaBuscada) >= 0
    );
    let postFiltFlexible = postFiltFecha.filter(post => {
        var textoTotal = JSON.stringify(post)
        var count = -1
        for(let i=0; i<textoBuscado.split(' ').length;i++){
            if(textoTotal.indexOf(textoBuscado.split(' ')[i]) >= 0){
                count ++;
            }
        }   
        if(count >= 0 && count===textoBuscado.split(' ').length-1){
            return true
        }else {
            return false
        }
    });
    //------------LOGICA DE FILTROS fin!!-------------------//
    let fechasUnicas = [...new Set(posts.data.map(post => post.fecha))];
    
    useEffect(() => {
        document.title = "Blog Autofix Express"
        dispatchPosts();
        window.scrollTo(0, 0);
    }, [dispatchPosts]);

    //Funcion de paginacion
    useEffect(() => {
        //calcula el numro de paginas y crea un objeto pagina
        setPages(Paginacion.getPagesArray(postFiltFlexible, pageSize));
        //paging
        let pagedArray = Paginacion.getRowsByPageIndex(
            postFiltFlexible,
            currentPageIndex,
            pageSize
        );
        //get sorted products
        setPostGrilla(pagedArray);
    }, [posts.loading,currentPageIndex,categoriaBuscada, etiquetasBuscadas,tituloBuscado,fechaBuscada,textoBuscado])

    let onPageIndexClicked = (clickedPageIndex) => {
        if (clickedPageIndex >= 0 && clickedPageIndex < pages.length)
        setCurrentPageIndex(clickedPageIndex);
    };
    
    let restablecerFiltros = () => {
        setCategoriaBuscada('')
        setEtiquetasBuscadas('')
        setTituloBuscado('')
        setFechaBuscada('')
    };

    let renderSwitch = (param) =>{
        switch(param) {
            case '1':
                return 'Enero';
            case '2':
                return 'Febrero';
            case '3':
                return 'Marzo';
            case '4':
                return 'Abril';
            case '5':
                return 'Mayo';
            case '6':
                return 'Junio';
            case '7':
                return 'Julio';
            case '8':
                return 'Agosto';
            case '9':
                return 'Septiembre';
            case '10':
                return 'Octubre';
            case '11':
                return 'Noviembre';
            case '12':
                return 'Diciembre';
            default:
        };
    }

    return (
        <React.Fragment>
            <div className='container-xxl' >
                <MDBBreadcrumb className='mt-2' style={{fontSize: '0.8rem'}}>
                <MDBBreadcrumbItem>
                <a className='text-dark' href='#/'>Inicio</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active>Blog</MDBBreadcrumbItem>
                </MDBBreadcrumb>   
                <h3>Posts Autofix Express Chile</h3>
            </div>
            <br/>
            <div className='container-xxl'>
                <div className='row '>
                    <div className='col-3' >
                        <MDBInputGroup className='mb-4'>
                            <MDBInputGroupText className='bg-danger'><MDBIcon fas icon="search" /></MDBInputGroupText>
                            <MDBInputGroupElement type='text' placeholder="Buscar" onChange={(e)=>setTextoBuscado(e.target.value)}/>
                        </MDBInputGroup>
                        <div className='container bg-white pb-4 pt-2' style={{border:'2px solid #c9c7c7'}}>
                        <MDBAccordion initialActive='flush-collapse1' flush>
                        <MDBAccordionItem collapseId='flush-collapse1' headerTitle='Categorias'>
                            <MDBListGroup flush >
                            <MDBListGroupItem tag='button' action type='button' 
                                onClick={()=> categoriaBuscada.indexOf('Reviews')>=0?setCategoriaBuscada(''):setCategoriaBuscada('Reviews')}
                                active={categoriaBuscada==='Reviews'?true:false} className='mr-4'
                            >
                                Reviews                                
                            </MDBListGroupItem>
                            <MDBListGroupItem tag='button' action type='button' 
                                onClick={()=> categoriaBuscada.indexOf('Mantencion')>=0?setCategoriaBuscada(''):setCategoriaBuscada('Mantencion')}
                                active={categoriaBuscada==='Mantencion'?true:false}
                            >
                                Mantencion
                            </MDBListGroupItem>
                            <MDBListGroupItem tag='button' action type='button' 
                                onClick={()=> categoriaBuscada.indexOf('Repuestos')>=0?setCategoriaBuscada(''):setCategoriaBuscada('Repuestos')}
                                active={categoriaBuscada==='Repuestos'?true:false}
                            >
                                Repuestos
                            </MDBListGroupItem>
                            <MDBListGroupItem tag='button' action type='button' 
                                onClick={()=> categoriaBuscada.indexOf('Equipamiento')>=0?setCategoriaBuscada(''):setCategoriaBuscada('Equipamiento')}
                                active={categoriaBuscada==='Equipamiento'?true:false}
                            >
                                Equipamiento
                            </MDBListGroupItem>
                            </MDBListGroup>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId='flush-collapse2' headerTitle='Etiquetas'>
                            <MDBBadge pill tag="button" 
                                onClick={()=> setEtiquetasBuscadas(etiquetasBuscadas+' autos')}
                                color={etiquetasBuscadas.indexOf('autos')>=0?'primary':'dark'}
                            >
                                autos
                            </MDBBadge>
                            <MDBBadge pill tag="button" 
                                onClick={()=> setEtiquetasBuscadas(etiquetasBuscadas+' bmw')}
                                color={etiquetasBuscadas.indexOf('bmw')>=0?'primary':'dark'}
                            >
                                bmw
                            </MDBBadge>
                            <MDBBadge pill tag="button" 
                                onClick={()=> setEtiquetasBuscadas(etiquetasBuscadas+' mercedes benz')}
                                color={etiquetasBuscadas.indexOf('mercedes benz')>=0?'primary':'dark'}
                            >
                                mercedes benz
                            </MDBBadge>
                            <MDBBadge pill tag="button" 
                                onClick={()=> setEtiquetasBuscadas(etiquetasBuscadas+' volvo')}
                                color={etiquetasBuscadas.indexOf('volvo')>=0?'primary':'dark'}
                            >
                                volvo
                            </MDBBadge>
                            <MDBBadge pill tag="button" 
                                onClick={()=> setEtiquetasBuscadas(etiquetasBuscadas+' mini')}
                                color={etiquetasBuscadas.indexOf('mini')>=0?'primary':'dark'}
                            >
                                mini
                            </MDBBadge>
                            <MDBBadge pill tag="button" 
                                onClick={()=> setEtiquetasBuscadas(etiquetasBuscadas+' motor')}
                                color={etiquetasBuscadas.indexOf('motor')>=0?'primary':'dark'}
                            >
                                motor
                            </MDBBadge>
                            <MDBBadge pill tag="button" 
                                onClick={()=> setEtiquetasBuscadas(etiquetasBuscadas+' cuidado')}
                                color={etiquetasBuscadas.indexOf('cuidado')>=0?'primary':'dark'}
                            >
                                cuidado
                            </MDBBadge>
                            <MDBBtn size='sm' className='mt-2' onClick={()=>setEtiquetasBuscadas('')}>Quitar etiquetas</MDBBtn>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId='flush-collapse3' headerTitle='Ultimas Entradas'>
                            <MDBListGroup flush >
                            {posts.data.slice(0,4).map((elem) => {
                                return(
                                    <MDBListGroupItem key={elem.codigo} tag='button' action type='button'
                                        onClick={()=> tituloBuscado.indexOf(elem.titulo)>=0?setTituloBuscado(''):setTituloBuscado(elem.titulo)}
                                        active={tituloBuscado===elem.titulo?true:false}
                                    >
                                        {elem.titulo}
                                    </MDBListGroupItem>
                                )
                            })}
                            </MDBListGroup>
                        </MDBAccordionItem>
                        <MDBAccordionItem collapseId='flush-collapse4' headerTitle='Fecha'>
                            <MDBListGroup flush >
                            {fechasUnicas.slice(0,4).map((elem,index) => {
                                return(
                                    <MDBListGroupItem key={index} tag='button' action type='button'
                                        onClick={()=> fechaBuscada.indexOf(elem)>=0?setFechaBuscada(''):setFechaBuscada(elem)}
                                        active={fechaBuscada===elem?true:false}
                                    >
                                        {elem}
                                    </MDBListGroupItem>
                                )
                            })}
                            </MDBListGroup>
                        </MDBAccordionItem>
                        </MDBAccordion>
                        <MDBBtn onClick={()=> restablecerFiltros()} className='mx-auto d-block mt-4' color='danger' outline>
                            Ver todo
                        </MDBBtn>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='row row-cols-1 text-center'>
                        {postsGrilla.map((elem)=>{
                            return (
                                <Link style={{color:"black",border:'2px solid #c9c7c7',backgroundColor:'white'}} key={elem.codigo}
                                    tag='div' className='col pt-4 pb-4'
                                    to={{
                                        pathname: "/post/"+elem.codigo,
                                    }}
                                >
                                    <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay' style={{backgroundColor:'black'}}>
                                    <img src={elem.imagen} className="img-fluid" alt='Blog 24 Bremse Chile: Venta y Distribución de Repuestos para autos de gama alta' style={{maxHeight:'400px'}} />
                                        <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                    </MDBRipple>
                                    <div className='container'>
                                    <div className='text-start' style={{color:'#858687',fontSize:'0.8rem'}}>
                                    <MDBIcon far icon="calendar-alt" color='danger'/> {elem.fecha.split('-')[0]} {renderSwitch(elem.fecha.split('-')[1])} {elem.fecha.split('-')[2]}
                                    </div>
                                    <h4 className='text-uppercase text-start'>{elem.titulo}</h4>
                                    <p className='text-center' style={{color:'#858687'}}>{elem.texto.substring(0,200)+'...'} </p>
                                    </div>
                                </Link> 
                            )
                        })}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-3'/>
                <div className='col'>
                    <ul className="pagination pagination-lg mt-1">
                        {pages.map((page) => (
                            <li
                            key={page.pageIndex}
                            className={
                                currentPageIndex === page.pageIndex
                                ? "page-item active"
                                : "page-item"
                            }
                            onClick={() => {
                                onPageIndexClicked(page.pageIndex);
                            }}
                            >
                            <a
                                className="page-link"
                                href="/#"
                                onClick={(event) => {
                                event.preventDefault();
                                }}
                            >
                                {page.pageIndex + 1}
                            </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    posts: state.blogposts,
});
  
const mapDispatchToProps = {
    dispatchPosts: actions.fetchBlogPosts,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPC);