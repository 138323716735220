import { productosReducer } from "./productos-reducers";
import { familiasReducer } from "./familias-reducer";
import { autosReducer } from "./autos-reducer";
import { desarmeReducer } from "./desarme-reducer";
import { blogReducer } from "./blog-reducer";
import { postReducer } from "./post-reducer";
import { combineReducers } from "redux";

var allReducers = combineReducers({ 
    productos: productosReducer,
    familias: familiasReducer,
    autos: autosReducer,
    desarme: desarmeReducer,
    blogposts:blogReducer,
    post:postReducer
});

export default allReducers;
