import * as actionTypes from "../constants/action-types";
import axios from "axios";

import {
  URL_FETCH_AUTOS_DESARME
} from "../config";

export const fetchAutosDesarme = () => async (dispatch) => {

    //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
    dispatch({ type: actionTypes.FETCH_AUTOS_DESARME});
    try
    {
      var response = await axios.get(`${URL_FETCH_AUTOS_DESARME}`);
  
      //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
      dispatch({
        type: actionTypes.FETCH_AUTOS_DESARME_SUCCES,
        payload: response.data
      });
    }
    catch(err)
    {
      //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
      dispatch({
        type: actionTypes.FETCH_AUTOS_DESARME_ERROR,
        payload: err
      });
    }
};

export const fetchAutoDesarme = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_AUTO_DESARME});
  try
  {
    var response = await axios.get(`${URL_FETCH_AUTOS_DESARME}/${props}`);

    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_AUTO_DESARME_SUCCES,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_AUTO_DESARME_ERROR,
      payload: err
    });
  }
};