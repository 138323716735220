import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from 'react'
import { connect } from "react-redux";
import actions from "../../actions";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function SliderProductos({familias ,dispatchFetchFamilias  }) {
  //-------- media querys --------//

  useEffect(() => {
    dispatchFetchFamilias();
  }, [ dispatchFetchFamilias]);

  const CustomRight = ({ onClick }) => (
    <a>
    <i className="fa fa-arrow-right flecha-slider" onClick={onClick} 
      style={{position:'absolute', right:'10px', fontSize:'2rem'}}
    />
    </a>
  );
  const CustomLeft = ({ onClick }) => (
    <a>
    <i className="fa fa-arrow-left flecha-slider" onClick={onClick} 
      style={{position:'absolute', left:'10px', fontSize:'2rem'}} 
    />
    </a>
  );

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1920 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }

  return (
      <div className='container-fluid mt-3'>
          <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          transitionDuration={500}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px" 
          centerMode={false}
          renderButtonGroupOutside={false} 
          arrows = {true}
          customRightArrow={<CustomRight />}
          customLeftArrow={<CustomLeft />}
          >
          {/* producto starts */}
            {
              familias.loading === true ?
                <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status" style={{width:'3rem',height:'3rem'}} >
                </div>
                </div>
                : 
                Object.keys(familias.data).map((familia,index) =>{
                  return (

                    <div className='col text-center' key={index}>
                      <Link style={{color:"black"}} 
                        to={{
                          pathname: "/catalogo",
                          state:{
                            fromBusquedaInicioSlider: true,
                            familia_b: familia
                          }
                        }}
                      >
                        <div className="card cartaMovil mx-auto">
                            <img src={familias.data[familia][0].imagen2}
                                className='imagenCartaMovil'
                                alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.'
                            />
                            <div className="card-body text-center">
                                <p className="card-title">{familias.data[familia][0].id_familia}</p>
                            </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
            }
          {/* producto ends */}

          </Carousel>
      </div>
  )
}

const mapStateToProps = (state) => ({
  familias: state.familias
});

const mapDispatchToProps = {
  dispatchFetchFamilias: actions.fetchFamilias
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderProductos);
