import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import Footer from '../Footer/Footer';
import { connect } from "react-redux";
import actions from "../../actions";
import { Link } from "react-router-dom";
import {useMediaQuery} from 'react-responsive';
import Carousel from "react-multi-carousel";
import {Helmet} from "react-helmet";
import {
    MDBBreadcrumb,MDBBreadcrumbItem,
    MDBListGroupItem
    } from 'mdb-react-ui-kit';

function Producto({autos, productos,dispatchFetchAutosxProducto,dispatchfetchProducto}) {
    let parametros = useParams();
    // ------ STATE -------
    let [showAutos, setShowAutos] = useState(false);
    let [showFichaTecnica, setShowFichaTecnica] = useState(false);
    let [showModelosAuto, setShowModelosAuto] = useState(false);
    let [imagenesProducto, setImagenesProducto] = useState([]);
    let [modeloSelec, setModeloSelec] = useState('');
    // ------ VARIABLES -------
    let modelosUnicos = [...new Set(autos.data.map(auto => auto.id_modelo))];
    // ------ MEDIA QUERYS -------
    const isDesktopOrLaptopSmall = useMediaQuery({ maxWidth: 1510 })
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
    }
    //-------- effects -------//
    useEffect(() => {
        dispatchFetchAutosxProducto(parametros);
        dispatchfetchProducto(parametros);
    }, [dispatchFetchAutosxProducto,dispatchfetchProducto, parametros]);

    useEffect(() => {
        autos.data[0]?
        setImagenesProducto([...new Set(autos.data.map(img => img.imagen2))])
        //setImagenesProducto([productos.data[0].imagen2])
        :
        void 0
    }, [autos.loading]); 
    //------- funciones, metodos, eventos... -------//
    let toggleAutos = () => {
        setShowAutos(!showAutos);
        setShowFichaTecnica(false);
        setShowModelosAuto(!showModelosAuto);
    };

    let toggleModelosAuto = () => {
        setShowModelosAuto(!showModelosAuto);
    };

    let toggleFichaTecnica = () => {
        setShowFichaTecnica(!showFichaTecnica);
        setShowAutos(false);
    };
    let onClickModelo = (elem)=>{
        setImagenesProducto(autos.data.filter(auto => 
            auto.id_modelo.indexOf(elem)>=0).map(img => img.imagen2))
        setModeloSelec(elem)
    };

    return (
        <React.Fragment>
            {productos.loading === true || productos.data[0]===undefined ? 
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-danger" role="status" style={{width:'3rem',height:'3rem'}} >
                        <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    :
            <div className="container-xxl mt-2 producto-box">
                <div style={isTabletOrMobile?{fontSize: '0.6rem'}:{fontSize: '0.8rem'}} >
                    <MDBBreadcrumb>
                    <MDBBreadcrumbItem><a className='text-dark' href='#/'>Inicio</a></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem><a className='text-dark' href='#/catalogo'>Catálogo</a></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem ><a className='text-dark' href='#/catalogo'>{productos.data[0].id_familia}</a></MDBBreadcrumbItem>
                    </MDBBreadcrumb>   
                </div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{productos.data[0].parte + productos.data[0].id_marca + productos.data[0].clase + " | 24 Bremse Chile"}</title>
                <meta name="description" content={"Encuentra " + productos.data[0].parte + productos.data[0].id_marca + productos.data[0].clase + ". Tenemos repuestos alternativos y originales para BMW, Mercedes Benz, Mini, Volvo, entre otros."}/>
            </Helmet>
                <div className="container-fluid vista-producto-box">
                    {isTabletOrMobile ? 
                        <h6>{productos.data[0].parte} {productos.data[0].id_marca} {productos.data[0].clase}</h6>
                        :
                        <h3>{productos.data[0].parte} {productos.data[0].id_marca} {productos.data[0].clase}</h3>
                    }
                    <div 
                        className={isTabletOrMobile?'row datos-productos-box shadow datosBoxProdMovil':
                                    'row datos-productos-box shadowdatosBoxProdMovil'}
                    >
                        <div className="col" style={isDesktopOrLaptopSmall?{maxWidth:'500px'}:{maxWidth:'650px'}}>
                            <Carousel
                                partialVisible={false}
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                keyBoardControl={true}
                                transitionDuration={500}
                                dotListClass="custom-dot-list-style"
                                centerMode={false}
                                renderButtonGroupOutside={false} 
                                arrows = {true}
                                renderButtonGroupOutside={false}
                            >
                            {imagenesProducto.map((elem,index)=>{
                                return(
                                    <div key={index}>
                                    <img src={elem} alt={"Encuentra " + productos.data[0].parte + productos.data[0].id_marca + productos.data[0].clase + ". Tenemos repuestos alternativos y originales para BMW, Mercedes Benz, Mini, Volvo, entre otros."}
                                        className={isTabletOrMobile? 'image-fluid imagenProdMovil' : 'image-fluid imagenProdGrande'}
                                    />
                                    </div>
                                )
                            })}
                            </Carousel>
                        </div>
                        <div className={isTabletOrMobile?'textoInformacionProdMovil col' : 'textoInformacionProdGrande col'}
                            style={isTabletOrMobile?void 0:{marginRight:'50px'}}
                        >
                            <div className="row">
                                <div className='container text-justify'>
                                    {isTabletOrMobile?<h6>Informacion</h6>:<h4>Informacion</h4>}
                                    <p style={isTabletOrMobile?{fontSize:'0.7rem'}:void 0}>
                                        {productos.data[0].descripcion.slice(productos.data[0].descripcion.indexOf("brindamos"),productos.data[0].descripcion.indexOf("----"))}
                                    </p>
                                </div>
                                {isTabletOrMobile? void 0 :
                                    <div className="shadow" style={{paddingTop:"30px", paddingBottom:'30px', backgroundColor:'#f0ececb4'}}>
                                        <p>Si no encuentra stock disponible, consulte por importacion aqui</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col" 
                            style={isTabletOrMobile?void 0 :{position:'relative'}}
                        >
                            <div 
                                className={isTabletOrMobile?'row align-items-center text-center shadow cotizarProdMovil':
                                'cotizarProdGrande row align-items-center text-center shadow'} 
                                style={isTabletOrMobile?{margin:'auto'}:{position:'absolute',top:'50%'}}
                            >
                                <div className="col"><p>Stock Disponible: {productos.data[0].cantidad > 0 ?
                                    <span  className="badge bg-success">Si</span>
                                    :
                                    <span className="badge bg-danger">No</span>
                                }</p></div>
                                <div className="container-fluid text-center">
                                    <Link style={{color:"white"}}
                                        to={{
                                            pathname: "/cotizaciones",
                                            state:{
                                                fromProducto: true,
                                                producto: productos
                                            }
                                        }}
                                    >
                                        <div className={isTabletOrMobile?"btn btn-danger btn-sm" : "btn btn-danger"} style={{width:"75%"}}>Cotizar</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid " 
                    style={isTabletOrMobile?{marginTop:"30px", marginBottom:"30px", fontSize:'0.8rem'}:{marginTop:"30px", marginBottom:"30px"}}
                >
                    <div className="card my-2 shadow" style={{width:"auto"}}>
                        <div className="card-body">
                        {isTabletOrMobile? 
                            <h6 className="card-title">Moldeos compatibles {productos.data[0].marcas}</h6>
                            :
                            <h5 className="card-title">Moldeos compatibles {productos.data[0].marcas}</h5>
                        }
                            <div className={isTabletOrMobile?'row row-cols-1':'row row-cols-3'}>
                                {modelosUnicos.map((elem,index) => {
                                    return (
                                        <div className='col' key={index}>
                                        <MDBListGroupItem tag='button' action aria-current='true' type='button' active={elem===modeloSelec?true:false}
                                            color='danger'
                                            onClick={()=> onClickModelo(elem)}
                                        >
                                            {elem}
                                        </MDBListGroupItem>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
            <Footer/>           
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    autos: state.autos,
    productos: state.productos,
});
  
const mapDispatchToProps = {
    dispatchFetchAutosxProducto: actions.fetchAutosxProducto,
    dispatchfetchProducto: actions.fetchProducto,
};

export default connect(mapStateToProps, mapDispatchToProps)(Producto);
