import React, {useEffect, useState} from "react";
import actions from "../../actions";
import Footer from "../Footer/Footer";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {PaginacionFamilias,Paginacion} from '../../services/Services';
import { Link } from "react-router-dom";
//import BannerPromocion from "../../assets/BannerPromo.png";
import {useMediaQuery} from 'react-responsive';
import { MDBBreadcrumb, MDBBreadcrumbItem,MDBCheckbox } from 'mdb-react-ui-kit';

import {
    URL_FETCH_AUTOS,
    URL_FETCH_MODELOS,
    URL_FETCH_MOTORES
} from "../../config";


function CatalogoPC({familias,productos ,dispatchFetchFamilias,dispatchFetchProductosFiltrados}) {
    //-------- state --------//
    let [productos_grilla, setProductosGrilla] = useState([]); //arreglo que sera mostrado en la grilla
    let [familias_grilla, setFamiliasGrilla] = useState([]);
    let [modelos_auto, setModelosAuto] = useState([]);
    let [carrocerias_auto, setCarroceriasAuto] = useState([]);
    let [motores, setMotores] = useState([]);
    let [busqueda, setBusqueda] = useState(""); 
    let [categoria, setCategoriaBuscada] = useState(""); 
    let [marca_auto_buscada, setMarcaAutoBuscada] = useState("");
    let [modelo_auto_buscado, setModeloAutoBuscado] = useState("");
    let [motor_buscado, setMotorBuscado] = useState("");
    let [anno_buscado, setAnnoBuscado] = useState("");
    let [carroceria_auto_buscada, setCarroceriaAutoBuscada] = useState("");
    let [panelInicial,setPanelInicial] = useState(true);
    //-------- paginacion --------//
    let [pagesProductos, setPagesProductos] = useState([]);
    let [pagesFamilia, setPagesFamilias] = useState([]);
    let [pageSize] = useState(12);
    let [currentPageIndex, setCurrentPageIndex] = useState(0);
    //-------- otros --------//
    let location = useLocation();
    let params_inicio = location.state
    const marcas_selector = ["Audi", "BMW", "Mercedes-benz", "Mini" , "Porsche", "Land Rover", "Volvo"];   
    //-------- media querys --------//
    const isDesktopOrLaptopSmall = useMediaQuery({ maxWidth: 1510 })
    //-------- funciones, metodos... -------//
    let restablecerFiltros = () => {
        setBusqueda("");
        setMarcaAutoBuscada("");
        setCarroceriaAutoBuscada("")
        setModeloAutoBuscado("");
        setMotorBuscado("");
        setAnnoBuscado("");
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
          );

        Array.from(document.querySelectorAll("select")).forEach(
            select => (select.value = "")
        );
    };  

    let productosFiltradosFamilia = Object.entries(productos.data).filter(prod => 
        prod[1][0] ?
        prod[1][0].id_marca.indexOf(marca_auto_buscada.toUpperCase()) >= 0
        :
        void 0
    );
    let productosFiltradosMarca = productosFiltradosFamilia.filter(prod => 
        prod[1][0] ?
        prod[1][0].id_marca.indexOf(marca_auto_buscada.toUpperCase()) >= 0
        :
        void 0
    );

    let familiasFiltradas = Object.entries(familias.data).filter(fam => 
        //console.log(fam)
       fam[1][0].id_familia.indexOf(categoria.toUpperCase()) >= 0
    );
    //-------- effects(funciones que se ejecutan en cada renderizacion)--------//
    useEffect(() => {
        document.title = "Catalogo"
        dispatchFetchFamilias();
        window.scrollTo(0, 0)
    }, [ dispatchFetchFamilias]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [panelInicial,setPanelInicial]);

    useEffect(() => {
        var filtros = {
            id_familia:busqueda,
            id_modelo: carroceria_auto_buscada,
            id_tipo: modelo_auto_buscado,
            id_motor: motor_buscado,
            anno: anno_buscado,
            marca:marca_auto_buscada,
        }
        dispatchFetchProductosFiltrados(filtros);
        //console.log("useefect de carga de prod filtrados",busqueda)
    }, [params_inicio,busqueda,carroceria_auto_buscada,modelo_auto_buscado,motor_buscado,anno_buscado,dispatchFetchProductosFiltrados,marca_auto_buscada]);

    useEffect(() => {
        if (! (typeof params_inicio === 'undefined')) {
            if(params_inicio.fromBusquedaInicioSlider){
                setBusqueda(params_inicio.familia_b);
                setPanelInicial(false);
            }else {
                console.log("dispatch")
                setBusqueda(params_inicio.familia_b.toUpperCase());
                setMarcaAutoBuscada(params_inicio.marca_auto_b.toUpperCase());
                setCarroceriaAutoBuscada(params_inicio.carroceria_b.toUpperCase());
                setModeloAutoBuscado(params_inicio.modelo_auto_b.toUpperCase());
                setAnnoBuscado(params_inicio.anno_b);
                setPanelInicial(false);
                dispatchFetchProductosFiltrados({
                    id_familia:busqueda,
                    id_modelo: carroceria_auto_buscada,
                    id_tipo: modelo_auto_buscado,
                    id_motor: motor_buscado,
                    anno: anno_buscado,
                    marca:marca_auto_buscada
                })
            }
        }
        //console.log("useefect de params inicio", params_inicio)
    }, [params_inicio])


    useEffect(() => {
        (async () => {
            //get Carrocerias
            let autosResponse = await fetch(
                `${URL_FETCH_AUTOS}/${marca_auto_buscada.toUpperCase()}`,
                { method: "GET" }
            );
            let autosResponseBody = await autosResponse.json();
            setCarroceriasAuto(autosResponseBody);
            //get Modelos
            let modelosResponse = await fetch(
                `${URL_FETCH_MODELOS}/${marca_auto_buscada.toUpperCase()}`,
                { method: "GET" }
            );
            let modelosResponseBody = await modelosResponse.json();
            setModelosAuto(modelosResponseBody);      
            //get Motores
            let motoresResponse = await fetch(
                `${URL_FETCH_MOTORES}/${marca_auto_buscada.toUpperCase()}`,
                { method: "GET" }
            );
            let motoresResponseBody = await motoresResponse.json();
            setMotores(motoresResponseBody);     
        })();
    }, [marca_auto_buscada]);


    useEffect(() => {
        setCurrentPageIndex(0);
    }, []);

    //Carga de paginas con grillas de productos a mostrar, 
    //se renderiza de acuerdo con los filtros que se hayan seleccionado
    useEffect(() => {
        //calcula el numro de paginas y crea un objeto pagina
        setPagesProductos(Paginacion.getPagesArray(productosFiltradosMarca, pageSize));
        //paging
        let pagedArray = Paginacion.getRowsByPageIndex(
            productosFiltradosMarca,
            currentPageIndex,
            pageSize
        );
        //get sorted products
        setProductosGrilla(pagedArray);
        //console.log("useEffect de filtro", productos)
    }, [carroceria_auto_buscada,busqueda,marca_auto_buscada, modelo_auto_buscado, motor_buscado,anno_buscado,pageSize, currentPageIndex, productos.loading]);

    useEffect(() => {
        //calcula el numro de paginas y crea un objeto pagina
        setPagesFamilias(PaginacionFamilias.getPagesArray(familiasFiltradas, pageSize));
        //paging
        let pagedArray = PaginacionFamilias.getRowsByPageIndex(
            familiasFiltradas,
            currentPageIndex,
            pageSize
        );
        //get sorted products
        setFamiliasGrilla(pagedArray);
    }, [familias.loading,pageSize,currentPageIndex, categoria])

    //-------- Eventos de click --------//
    let clickPromocion = () => {
        setCategoriaBuscada("AMORTIGUADORES");
    };
    //Cuando el usuario haga click en alguna indice de pagina:
    let onPageProductoIndexClicked = (clickedPageIndex) => {
        if (clickedPageIndex >= 0 && clickedPageIndex < pagesProductos.length)
        setCurrentPageIndex(clickedPageIndex);
    };
    let onPageFamiliaIndexClicked = (clickedPageIndex) => {
        if (clickedPageIndex >= 0 && clickedPageIndex < pagesFamilia.length)
        setCurrentPageIndex(clickedPageIndex);
    };
    let onClickProductoVerMas = (elem) => {
        setBusqueda(elem[1][0].id_familia);
        setPanelInicial(false);
        setCurrentPageIndex(0);    
    };
    let handleBreadcrumb = () => {
        setPanelInicial(true)
        setBusqueda('')
    };
    //-------- Eventos de click fin--------//

    return (
        <React.Fragment>
        {/* 
        <a href='#/catalogo'>
        <img src={BannerPromocion} 
            className="img-fluid" alt='imagen' onClick={clickPromocion}
        />
        </a>
        */}
        <div className="container mt-2" style={{fontSize: '0.8rem'}}>
            <MDBBreadcrumb>
            <MDBBreadcrumbItem>
            <a className='text-dark' href='#/'>Inicio</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active={busqueda===''?true:false} >
            <a className='text-dark' href='#/catalogo' onClick={()=>handleBreadcrumb()}>Catálogo</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>{busqueda}</MDBBreadcrumbItem>
            </MDBBreadcrumb>    
        </div>

        <div className="container-fluid catalogo-box">
            <div className="row g-0" id='filtros'>
                <div className={isDesktopOrLaptopSmall ? 'textoMedio col-6 col-md-2':'textoGrande col-6 col-md-2'}>
                    <div className="container shadow p-3 rounded" 
                        style={{backgroundColor:'white'}}
                    >
                        <div className="form-floating mb-3" >
                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" 
                                onChange= {(event) => {setMarcaAutoBuscada(event.target.value.toUpperCase())}}   
                                defaultValue={location.state === undefined ? '' : location.state.marca_auto_b}
                            >
                                <option ></option>
                                {/* Imprimir marcas para el selector de filtros */}
                                {marcas_selector.map( (marca_auto, index) =>
                                    <option key={index} value={marca_auto}>{marca_auto}</option>
                                )}
                            </select>
                            <label htmlFor="floatingSelect">Marca de auto</label>
                        </div>

                        <div className="form-floating mb-3" >
                            <input type="search" list="datalistOptions2" className="form-control" id="floatingInput"  placeholder=""
                            defaultValue={carroceria_auto_buscada} onChange= {(event) => {setCarroceriaAutoBuscada(event.target.value.toUpperCase())}}
                            />
                            <label htmlFor="floatingInput">Carroceria</label>
                            <datalist id="datalistOptions2">
                                {/*familias start */}
                                {carrocerias_auto.map( (carro,index)=> 
                                    <option key={index}>{carro.id_modelo}</option>
                                )}
                                {/*familias end */}
                            </datalist>
                        </div>

                        <div className="form-floating mb-3" >
                            <input type="search" list="datalistOptions3" className="form-control" id="floatingInput"  placeholder=""
                            defaultValue={modelo_auto_buscado} onChange= {(event) => {setModeloAutoBuscado(event.target.value)}}
                            />
                            <label htmlFor="floatingInput">Modelo</label>
                            <datalist id="datalistOptions3">
                                {/*familias start */}
                                {modelos_auto.map( (modelo,index)=> 
                                    <option key={index}>{modelo.id_tipo}</option>
                                )}
                                {/*familias end */}
                            </datalist>
                        </div>

                        <div className="form-floating mb-3" >
                            <input type="search" list="datalistOptions4" className="form-control" id="floatingInput" placeholder=""
                            defaultValue={motor_buscado} onChange= {(event) => {setMotorBuscado(event.target.value.toUpperCase())}}
                            />
                            <label htmlFor="floatingInput">Modelo de motor</label>
                            <datalist id="datalistOptions4">
                                {/*familias start */}
                                {motores.map( (motor,index)=> 
                                    <option key={index}>{motor.id_motor}</option>
                                )}
                                {/*familias end */}
                            </datalist>
                        </div>  
                        
                        <div className="form-floating mb-3" >
                            <input type="text" className="form-control" id="floatingInput" placeholder=""
                                defaultValue={anno_buscado} onChange= {(event) => {setAnnoBuscado(event.target.value.substr(-2))}}
                            />
                            <label htmlFor="floatingInput">Año de tu auto</label>
                        </div>
                        
                        <div className="form-floating mb-3" >
                            {isDesktopOrLaptopSmall ? 
                                <button type="button" className="btn btn-sm btn-primary"
                                onClick={() => {restablecerFiltros()}}
                                >
                                    Restablecer Filtros
                                </button>
                                :
                                <button type="button" className="botonRojo btn text-white"
                                onClick={() => {restablecerFiltros()}}
                                >
                                    Restablecer Filtros
                                </button> 
                            }
                        
                        </div>
                    </div>
                </div>

                {panelInicial ?
                    <div className="col-sm-6 col-md-10">
                        <div className='nav shadow-sm ' 
                            style={{margin:'0px 30px 18px 18px', height:'60px', paddingTop:'10px', paddingBottom:'15px', backgroundColor:'white'}}
                        >
                            <p className='nav-item'>Categorias de repuestos :</p>
                            <form className="d-flex nav-item">
                                <input className="form-control me-2" type="search" placeholder="Buscar" list="datalistOptions" aria-label="Search" 
                                    onChange= {(event) => {setCategoriaBuscada(event.target.value)}}
                                />
                            {familias.loading === true ? 
                                void 0 
                                :
                                <datalist id="datalistOptions">
                                    {/*familias start */}
                                    {Object.keys(familias.data).map((familia, index) =>
                                    <option key={index} >
                                        {familia}
                                    </option>
                                    )}
                                    {/*familias end */}
                                </datalist>
                            }
                            </form>
                        </div>
                            {/*familias start */}
                            {familias_grilla.length === 0 ?
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-primary" role="status" style={{width:'3rem',height:'3rem'}} >
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                    :
                                <div className='row row-cols-4'>
                                    {familias_grilla.map((elem,index) => {
                                        return (
                                            <div className='col text-center' key={index}>
                                                <div className={isDesktopOrLaptopSmall? 'cartaMedio card': 'cartaGrande card'} >
                                                    <img src={elem[1][0].imagen2} 
                                                        className='imagenCartaMedio'
                                                        alt='Encuentra componentes, repuestos originales y alternativos para autos de alta gama, trabajamos con marcas de calidad como Zimmerman, AL-KO, ALCO, HEPU, ElRing'
                                                    />
                                                    <div className="card-body text-center">
                                                        <div>
                                                            <h5 className="card-title">{elem[1][0].id_familia}</h5>
                                                            <button onClick= {() => {onClickProductoVerMas(elem);}}
                                                                className={isDesktopOrLaptopSmall ? 'btn btn-sm botonMedio' : 'btn btn-primary botonGrande'}
                                                            >
                                                                Ver Mas
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>                                                                  
                            }
                    </div>
                    :
                    <div className="col-sm-6 col-md-10">
                        <button onClick= {() => {restablecerFiltros();setPanelInicial(true);}}
                            className={isDesktopOrLaptopSmall ? 'btn botonRojo text-white btn-sm' : 'btn botonRojo text-white'}
                            style={{margin:'0px 0px 20px 18px'}}
                        >
                            Ver Categorias
                        </button>
                        <div className='row row-cols-4'>
                        {productos.loading === true ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary" role="status" style={{width:'3rem',height:'3rem'}} >
                                <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            (productos_grilla).map((elem,index) => {
                                return (
                                    <div className='col text-center' key={index}>
                                        <div className={isDesktopOrLaptopSmall?'cartaMedio card':'cartaGrande card'}>
                                            <img src={elem[1][0].imagen2} 
                                                className='imagenCartaMedio'
                                                alt='Encuentra componentes, repuestos originales y alternativos para autos de alta gama, trabajamos con marcas de calidad como Zimmerman, AL-KO, ALCO, HEPU, ElRing'
                                            />
                                            <div className="card-body text-center">
                                                <div>
                                                <h5 className="card-title">{elem[1][0].id_familia}</h5>
                                                <p className='card-text'>{elem[1][0].id_marca} {elem[1][0].clase}</p>
                                                <Link style={{color:"white"}}
                                                to={{
                                                    pathname: "/producto/"+elem[1][0].parte+"/"+elem[1][0].id_marca+"/"+elem[1][0].clase,
                                                }}
                                                >
                                                    <button type="button" 
                                                        className={isDesktopOrLaptopSmall?'btn btn-primary btn-sm botonMedio':'btn btn-primary botonGrande'}
                                                    > 
                                                        Ver Más
                                                    </button> 
                                                </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    </div>
                }
            </div>
            <div className="row">
                <div className='col-2'></div>

                <div className='col'>
                    {panelInicial ? 
                        <ul className="pagination pagination-lg mt-1">
                            {pagesFamilia.map((page) => (
                                <li
                                key={page.pageIndex}
                                className={
                                    currentPageIndex === page.pageIndex
                                    ? "page-item active"
                                    : "page-item"
                                }
                                onClick={() => {
                                    onPageFamiliaIndexClicked(page.pageIndex);
                                }}
                                >
                                <a
                                    className="page-link "
                                    href="/#"
                                    onClick={(event) => {
                                    event.preventDefault();
                                    }}
                                >
                                    {page.pageIndex + 1}
                                </a>
                                </li>
                            ))}
                        </ul>
                        :
                        <ul className="pagination pagination-lg mt-1">
                            {pagesProductos.map((page) => (
                                <li
                                key={page.pageIndex}
                                className={
                                    currentPageIndex === page.pageIndex
                                    ? "page-item active"
                                    : "page-item"
                                }
                                onClick={() => {
                                    onPageProductoIndexClicked(page.pageIndex);
                                }}
                                >
                                <a
                                    className="page-link"
                                    href="/#"
                                    onClick={(event) => {
                                    event.preventDefault();
                                    }}
                                >
                                    {page.pageIndex + 1}
                                </a>
                                </li>
                            ))}
                        </ul>
                    }
                </div>
            </div>
        </div>
        <Footer/>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    familias: state.familias,
    productos: state.productos
});
  
const mapDispatchToProps = {
    dispatchFetchFamilias: actions.fetchFamilias,
    dispatchFetchProductosFiltrados: actions.fetchProductosFiltrados
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoPC);
