import React,{useState, useEffect} from 'react';
import actions from "../../actions";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';

import {
    URL_FETCH_AUTOS,
    URL_FETCH_MODELOS
} from "../../config";

function BuscarPieza({familias ,dispatchFetchFamilias}) {
    let [busqueda, setBusqueda] = useState(""); 
    let [marca_auto_buscada, setMarcaAutoBuscada] = useState("");
    let [carroceria_auto_buscada, setCarroceriaAutoBuscada] = useState("");
    let [modelo_auto_buscado, setModeloAutoBuscado] = useState("");
    let [anno_buscado, setAnnoBuscado] = useState("");
    let [carrocerias_auto, setCarroceriasAuto] = useState([]);
    let [modelos_auto, setModelosAuto] = useState([]);
    //-------- utilidades -------//
    const marcas_selector = ["Audi", "BMW", "Mercedes-benz", "Mini" , "Porsche", "Land Rover", "Volvo"];
    //-------- media querys --------//
    const isDesktopOrLaptopHDPI = useMediaQuery({ maxWidth: 1510 })
    const isDesktopOrLaptopMDPI = useMediaQuery({ maxWidth: 1400 },{
        query: "(max-device-height: 800px)",
    })
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    const isDesktopOrLaptopTouch = useMediaQuery({
        query: "(min-device-height: 950px)",
    });
    const isDesktopOrLaptopHP = useMediaQuery({
        query: "(max-device-height: 800px)",
    })

    //------- effects --------//
    useEffect(() => {
        dispatchFetchFamilias();
        window.scrollTo(0, 0);
    }, [ dispatchFetchFamilias ]);

    useEffect(() => {
        (async () => {
        //get Carrocerias
        let autosResponse = await fetch(
            `${URL_FETCH_AUTOS}/${marca_auto_buscada.toUpperCase()}`,
            { method: "GET" }
        );
        let autosResponseBody = await autosResponse.json();
        setCarroceriasAuto(autosResponseBody);
        //get Modelos
        let modelosResponse = await fetch(
            `${URL_FETCH_MODELOS}/${marca_auto_buscada.toUpperCase()}`,
            { method: "GET" }
        );
        let modelosResponseBody = await modelosResponse.json();
        setModelosAuto(modelosResponseBody);        
        })();
    }, [marca_auto_buscada]);

    return (
        <React.Fragment>
            {isTabletOrMobile ? void 0 :
            <div className='buscar-pieza'>
                <h2 className="searchTitle"><b>BUSCAR PIEZA</b></h2>
                <div className="row">
                    <div className="col-sm">
                        <div className="form-floating mb-3">
                            <input defaultValue={busqueda} type="search" list="datalistOptions" className="form-control" id="floatingInput"
                            onChange={(event) => {setBusqueda(event.target.value);}} placeholder=""/>
                                <label htmlFor="floatingInput">Repuesto</label>
                                <datalist id="datalistOptions">
                                    {/*familias start */}
                                    {Object.keys(familias.data).map((familia, index) =>
                                        <option key={index} >
                                            {familia}
                                        </option>
                                    )}
                                    {/*familias end */}
                                </datalist>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="form-floating mb-3">
                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example" 
                                defaultValue={marca_auto_buscada} onChange= {(event) => {setMarcaAutoBuscada(event.target.value)}}
                            >
                                <option ></option>
                                {/* Imprimir marcas para el selector de filtros */}
                                {marcas_selector.map( (marca_auto, index) =>
                                    <option key={index} value={marca_auto}>{marca_auto}</option>
                                )}
                            </select>
                            <label htmlFor="floatingSelect">Marca de auto</label>
                        </div>
                    </div>
                    <div className="col-sm">
                        {/*modelos */}
                        <div className="form-floating mb-3">
                            <input type="search" list="datalistOptions2" className="form-control" id="floatingInput"  placeholder=""
                                defaultValue={carroceria_auto_buscada} onChange= {(event) => {setCarroceriaAutoBuscada(event.target.value)}}
                            />
                                <label htmlFor="floatingInput">Carroceria</label>
                                <datalist id="datalistOptions2">
                                    {/*familias start */}
                                    {carrocerias_auto.map( (carro,index)=> 
                                        <option key={index}>{carro.id_modelo}</option>
                                    )}
                                    {/*familias end */}
                                </datalist>
                        </div>
                    </div>
                    <div className="col-sm">
                        {/*modelos */}
                        <div className="form-floating mb-3">
                            <input type="search" list="datalistOptions3" className="form-control" id="floatingInput"  placeholder=""
                                defaultValue={modelo_auto_buscado} onChange= {(event) => {setModeloAutoBuscado(event.target.value)}}
                            />
                                <label htmlFor="floatingInput">Modelo</label>
                                <datalist id="datalistOptions3">
                                    {/*familias start */}
                                    {modelos_auto.map( (modelo,index)=> 
                                        <option key={index}>{modelo.id_tipo}</option>
                                    )}
                                    {/*familias end */}
                                </datalist>
                        </div>
                    </div>
                    <div className="col-sm">
                        {/*años*/}
                        <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="floatingInput" placeholder=""
                            defaultValue={anno_buscado} onChange= {(event) => {setAnnoBuscado(event.target.value.substr(-2))}}
                        />
                            <label htmlFor="floatingInput">Año de tu auto</label>
                        </div>
                    </div>
                    <div className="col-sm">
                        <Link className='btn btn-lg text-white'
                            style={{backgroundColor:'#f10c0c'}}
                            to={{
                                pathname: "/catalogo",
                                state:{
                                    fromBusquedaInicio: true,
                                    familia_b: busqueda,
                                    marca_auto_b: marca_auto_buscada,
                                    carroceria_b: carroceria_auto_buscada,
                                    modelo_auto_b: modelo_auto_buscado,
                                    anno_b: anno_buscado  
                                }
                            }}
                        >
                            Buscar
                        </Link>  
                    </div>
                </div>
            </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    familias: state.familias
  });
  
  const mapDispatchToProps = {
    dispatchFetchFamilias: actions.fetchFamilias
  };

export default connect(mapStateToProps, mapDispatchToProps)(BuscarPieza);
