import * as actionTypes from "../constants/action-types";

//reducer is a function that receives an action and returns new state.
let initialState = { data: [], loading: true, error: "" };

export const postReducer = (state = initialState, action) => {
  switch (action.type)
  {
    case actionTypes.FETCH_POST:
      return { data: [], loading: true, error: ""};

    case actionTypes.FETCH_POST_SUCCESS:
      return { data: action.payload, loading: false, error: ""};
  
    case actionTypes.FETCH_POST_ERROR:
      return { data: state, loading: false, error: action.payload};
  
    default:
      return state;
  }
};
