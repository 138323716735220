//API HOST
require('dotenv').config();

export const API_HOST = process.env.REACT_APP_API_HOST;

export const URL_FETCH_AUTOS = API_HOST + '/autos';
export const URL_FETCH_AUTOS_POR_PRODUCTO = API_HOST + '/autosxprod';
export const URL_FETCH_BANNER_INICIO = API_HOST + '/banner_inicio';
export const URL_FETCH_BANNER_COTIZACIONES = API_HOST + '/banner_cotizaciones';
export const URL_FETCH_BLOG_POSTS = API_HOST + '/blogposts';
export const URL_POST_POST = API_HOST + '/post';
export const URL_FETCH_AUTOS_DESARME = API_HOST + '/desarme';
export const URL_FETCH_FAMILIAS = API_HOST + '/familias';
export const URL_FETCH_PRODUCTOS_DESTACADOS = API_HOST + '/productosDest';
export const URL_FETCH_PRODUCTOS = API_HOST + '/productos';
export const URL_FETCH_PRODUCTOS_FILTRADOS = API_HOST + '/productosFiltAutofix';
export const URL_FETCH_PRODUCTO = API_HOST + '/producto';

//CatalogoMovil
export const URL_FETCH_MODELOS = API_HOST + '/modelos';
export const URL_FETCH_MOTORES = API_HOST + '/motores';

//Cotizacion
export const URL_POST_COTIZACION = API_HOST + '/sendCoti';

//Footer
export const URL_FETCH_SUBSCRIBIR = API_HOST + '/sub';