import {fetchProductos,fetchProducto, fetchProductosFamilia,fetchProductosFiltrados } from "./productos"; 
import {fetchAutosDesarme, fetchAutoDesarme} from "./Desarme";
import {fetchFamilias } from "./familias";
import {fetchAutosxProducto } from "./Autos";
import { fetchBlogPosts,fetchPost } from "./Blog";


var actions = { 
    fetchFamilias, 
    fetchProductos,
    fetchProductosFamilia,
    fetchAutosxProducto ,
    fetchProducto,
    fetchAutosDesarme,
    fetchAutoDesarme,
    fetchProductosFiltrados,
    fetchBlogPosts,
    fetchPost
};
export default actions;
