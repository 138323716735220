export const FETCH_PRODUCTOS = "FETCH_PRODUCTOS";
export const FETCH_PRODUCTOS_SUCCESS = "FETCH_PRODUCTOS_SUCCESS";
export const FETCH_PRODUCTOS_ERROR = "FETCH_PRODUCTOS_ERROR";

export const FETCH_PRODUCTOS_FAMILIA = "FETCH_PRODUCTOS_FAMILIA";
export const FETCH_PRODUCTOS_FAMILIA_SUCCESS = "FETCH_PRODUCTOS_FAMILIA_SUCCESS";
export const FETCH_PRODUCTOS_FAMILIA_ERROR = "FETCH_PRODUCTOS_FAMILIA_ERROR";

export const FETCH_PRODUCTOS_FILTRADOS = "FETCH_PRODUCTOS_FILTRADOS";
export const FETCH_PRODUCTOS_FILTRADOS_SUCCESS = "FETCH_PRODUCTOS_FILTRADOS_SUCCESS";
export const FETCH_PRODUCTOS_FILTRADOS_ERROR = "FETCH_PRODUCTOS_FILTRADOS_ERROR";

export const FETCH_PRODUCTO = "FETCH_PRODUCTO";
export const FETCH_PRODUCTO_SUCCESS = "FETCH_PRODUCTO_SUCCESS";
export const FETCH_PRODUCTO_ERROR = "FETCH_PRODUCTO_ERROR";

export const FETCH_FAMILIAS = "FETCH_FAMILIAS";
export const FETCH_FAMILIAS_SUCCESS = "FETCH_FAMILIAS_SUCCESS";
export const FETCH_FAMILIAS_ERROR = "FETCH_FAMILIAS_ERROR";

export const FETCH_AUTOS = "FETCH_AUTOS";
export const FETCH_AUTOS_SUCCES = "FETCH_AUTOS_SUCCES";
export const FETCH_AUTOS_ERROR = "FETCH_AUTOS_ERROR";

export const FETCH_AUTOS_X_PRODUCTO = "FETCH_AUTOS_X_PRODUCTO";
export const FETCH_AUTOS_X_PRODUCTO_SUCCES = "FETCH_AUTOS_X_PRODUCTO_SUCCES";
export const FETCH_AUTOS_X_PRODUCTO_ERROR = "FETCH_AUTOS_X_PRODUCTO_ERROR";

export const FETCH_AUTOS_DESARME = "FETCH_AUTOS_DESARME";
export const FETCH_AUTOS_DESARME_SUCCES = "FETCH_AUTOS_DESARME_SUCCES";
export const FETCH_AUTOS_DESARME_ERROR = "FETCH_AUTOS_DESARME_ERROR";

export const FETCH_AUTO_DESARME = "FETCH_AUTO_DESARME";
export const FETCH_AUTO_DESARME_SUCCES = "FETCH_AUTO_DESARME_SUCCES";
export const FETCH_AUTO_DESARME_ERROR = "FETCH_AUTO_DESARME_ERROR";

export const FETCH_BLOG_POSTS = "FETCH_BLOG_POSTS";
export const FETCH_BLOG_POSTS_SUCCESS = "FETCH_BLOG_POSTS_SUCCESS";
export const FETCH_BLOG_POSTS_ERROR = "FETCH_BLOG_POSTS_ERROR";

export const FETCH_POST = "FETCH_POST";
export const FETCH_POST_SUCCESS = "FETCH_POST_SUCCESS";
export const FETCH_POST_ERROR = "FETCH_POST_ERROR";