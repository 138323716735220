import React, {useEffect, useState} from "react";
import actions from "../../actions";
import Footer from "../Footer/Footer";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdb-react-ui-kit';
import {PaginacionFamilias, Paginacion} from '../../services/Services';
import { Link } from "react-router-dom";
//import BannerPromocionMovil from "../../assets/BannerPromo-movil.png";
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';

import {
    URL_FETCH_AUTOS,
    URL_FETCH_MODELOS,
    URL_FETCH_MOTORES
} from "../../config";


function CatalogoMovil({familias,productos ,dispatchFetchFamilias,dispatchFetchProductosFiltrados}) {
    //-------- state --------//
    let [productos_grilla, setProductosGrilla] = useState([]); //arreglo que sera mostrado en la grilla
    let [familias_grilla, setFamiliasGrilla] = useState([]);
    let [modelos_auto, setModelosAuto] = useState([]);
    let [carrocerias_auto, setCarroceriasAuto] = useState([]);
    let [motores, setMotores] = useState([]);
    let [busqueda, setBusqueda] = useState(""); 
    let [categoria, setCategoriaBuscada] = useState(""); 
    let [marca_auto_buscada, setMarcaAutoBuscada] = useState("");
    let [modelo_auto_buscado, setModeloAutoBuscado] = useState("");
    let [motor_buscado, setMotorBuscado] = useState("");
    let [anno_buscado, setAnnoBuscado] = useState("");
    let [carroceria_auto_buscada, setCarroceriaAutoBuscada] = useState("");
    let [panelInicial,setPanelInicial] = useState(true);
    //-------- paginacion --------//
    let [pagesProductos, setPagesProductos] = useState([]);
    let [pagesFamilia, setPagesFamilias] = useState([]);
    let [pageSize] = useState(12);
    let [currentPageIndex, setCurrentPageIndex] = useState(0);
    //-------- otros --------//
    let location = useLocation();
    let params_inicio = location.state
    const marcas_selector = ["Audi", "BMW", "Mercedes-benz", "Mini" , "Porsche", "Land Rover", "Volvo"];   

    let restablecerFiltros = () => {
        setBusqueda("");
        setMarcaAutoBuscada("");
        setCarroceriaAutoBuscada("")
        setModeloAutoBuscado("");
        setMotorBuscado("");
        setAnnoBuscado("");
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
          );

        Array.from(document.querySelectorAll("select")).forEach(
            select => (select.value = "")
        );
    };  

    let productosFiltradosFamilia = Object.entries(productos.data).filter(prod => 
        prod[1][0] ?
        prod[1][0].id_marca.indexOf(marca_auto_buscada.toUpperCase()) >= 0
        :
        void 0
    );
    let productosFiltradosMarca = productosFiltradosFamilia.filter(prod => 
        prod[1][0] ?
        prod[1][0].id_marca.indexOf(marca_auto_buscada.toUpperCase()) >= 0
        :
        void 0
    );

    let familiasFiltradas = Object.entries(familias.data).filter(fam => 
        //console.log(fam)
       fam[1][0].id_familia.indexOf(categoria.toUpperCase()) >= 0
    );
    
    //-------- effects -------//
    useEffect(() => {
        document.title = "Catalogo"
        dispatchFetchFamilias();
        window.scrollTo(0, 0);
    }, [ dispatchFetchFamilias]);

    useEffect(() => {
        var filtros = {
            id_familia:busqueda,
            id_modelo: carroceria_auto_buscada,
            id_tipo: modelo_auto_buscado,
            id_motor: motor_buscado,
            anno: anno_buscado,
            marca:marca_auto_buscada,
        }
        dispatchFetchProductosFiltrados(filtros);
        //console.log("useefect de carga de prod filtrados",busqueda)
    }, [params_inicio,busqueda,carroceria_auto_buscada,modelo_auto_buscado,motor_buscado,anno_buscado,dispatchFetchProductosFiltrados,marca_auto_buscada]);

    useEffect(() => {
        if (! (typeof params_inicio === 'undefined')) {
            if(params_inicio.fromBusquedaInicioSlider){
                setBusqueda(params_inicio.familia_b);
                setPanelInicial(false);
            }else {
                console.log("dispatch")
                setBusqueda(params_inicio.familia_b.toUpperCase());
                setMarcaAutoBuscada(params_inicio.marca_auto_b.toUpperCase());
                setCarroceriaAutoBuscada(params_inicio.carroceria_b.toUpperCase());
                setModeloAutoBuscado(params_inicio.modelo_auto_b.toUpperCase());
                setAnnoBuscado(params_inicio.anno_b);
                setPanelInicial(false);
                dispatchFetchProductosFiltrados({
                    id_familia:busqueda,
                    id_modelo: carroceria_auto_buscada,
                    id_tipo: modelo_auto_buscado,
                    id_motor: motor_buscado,
                    anno: anno_buscado,
                    marca:marca_auto_buscada
                })
            }
        }
        //console.log("useefect de params inicio", params_inicio)
    }, [params_inicio])

    useEffect(() => {
        (async () => {
            //get Carrocerias
            let autosResponse = await fetch(
                `${URL_FETCH_AUTOS}/${marca_auto_buscada.toUpperCase()}`,
                { method: "GET" }
            );
            let autosResponseBody = await autosResponse.json();
            setCarroceriasAuto(autosResponseBody);
            //get Modelos
            let modelosResponse = await fetch(
                `${URL_FETCH_MODELOS}/${marca_auto_buscada.toUpperCase()}`,
                { method: "GET" }
            );
            let modelosResponseBody = await modelosResponse.json();
            setModelosAuto(modelosResponseBody);      
            //get Motores
            let motoresResponse = await fetch(
                `${URL_FETCH_MOTORES}/${marca_auto_buscada.toUpperCase()}`,
                { method: "GET" }
            );
            let motoresResponseBody = await motoresResponse.json();
            setMotores(motoresResponseBody);     
        })();
    }, [marca_auto_buscada]);



    useEffect(() => {
        setCurrentPageIndex(0);
    }, []);

    //Carga de paginas con grillas de productos a mostrar, 
    //se renderiza de acuerdo con los filtros que se hayan seleccionado
    useEffect(() => {
        //calcula el numro de paginas y crea un objeto pagina
        setPagesProductos(Paginacion.getPagesArray(productosFiltradosMarca, pageSize));
        //paging
        let pagedArray = Paginacion.getRowsByPageIndex(
            productosFiltradosMarca,
            currentPageIndex,
            pageSize
        );
        //get sorted products
        setProductosGrilla(pagedArray);
        //console.log("useEffect de filtro", productos)
    }, [carroceria_auto_buscada,busqueda,marca_auto_buscada, modelo_auto_buscado, motor_buscado,anno_buscado,pageSize, currentPageIndex, productos.loading]);

    useEffect(() => {
        //calcula el numro de paginas y crea un objeto pagina
        setPagesFamilias(PaginacionFamilias.getPagesArray(familiasFiltradas, pageSize));
        //paging
        let pagedArray = PaginacionFamilias.getRowsByPageIndex(
            familiasFiltradas,
            currentPageIndex,
            pageSize
        );
        //get sorted products
        setFamiliasGrilla(pagedArray);
    }, [familias.loading,pageSize,currentPageIndex, categoria])

    //-------- Eventos de click --------//
    //let clickPromocion = () => {
    //    setBusqueda("AMORTIGUADORES");
    //};
    //Cuando el usuario haga click en alguna indice de pagina:
    let onPageProductoIndexClicked = (clickedPageIndex) => {
        if (clickedPageIndex >= 0 && clickedPageIndex < pagesProductos.length)
        setCurrentPageIndex(clickedPageIndex);
    };
    let onPageFamiliaIndexClicked = (clickedPageIndex) => {
        if (clickedPageIndex >= 0 && clickedPageIndex < pagesFamilia.length)
        setCurrentPageIndex(clickedPageIndex);
    };
    let onClickProductoVerMas = (elem) => {
        setBusqueda(elem[1][0].id_familia)
        setPanelInicial(false)
        setCurrentPageIndex(0)
    };
    let handleBreadcrumb = () => {
        setPanelInicial(true)
        setBusqueda('')
    };
    //-------- Eventos de click fin--------//

    return (
        <React.Fragment>
        {/* 
        <img src={BannerPromocionMovil} 
            className="img-fluid" alt='imagen' onClick={clickPromocion}
        />
        */}
        <div className="container mt-2" style={{fontSize: '0.8rem'}}>
            <MDBBreadcrumb>
            <MDBBreadcrumbItem>
            <a className='text-dark' href='#/'>Inicio</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem>
            <a className='text-dark' href='#/catalogo' onClick={()=>handleBreadcrumb()}>Catálogo</a>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>{busqueda}</MDBBreadcrumbItem>
            </MDBBreadcrumb>   
        </div>

        <div className="container-fluid">
            <div className="row g-0">
                <div className='col'> 
                    <MDBAccordion flush >
                        <MDBAccordionItem collapseId='flush-collapse1' headerTitle='FILTROS'>
                        <div className="d-grid gap-2">
                            <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                                onChange= {(event) => {setMarcaAutoBuscada(event.target.value.toUpperCase())}}   
                                defaultValue={location.state === undefined ? '' : location.state.marca_auto_b}
                            >
                                <option >Selecciona marca de auto</option>
                                {/* Imprimir marcas para el selector de filtros */}
                                {marcas_selector.map( (marca_auto, index) =>
                                    <option key={index} value={marca_auto}>{marca_auto}</option>
                                )}
                            </select>

                            <input className="form-control form-control-sm" type="text" placeholder="Categoria Repuesto" aria-label=".form-control-sm example" 
                                defaultValue={busqueda} list="datalistOptions" onChange={(event) => {setBusqueda(event.target.value);}}
                            />
                                {familias.loading === true ? 
                                    void 0 
                                    :
                                    <datalist id="datalistOptions">
                                        {/*familias start */}
                                        {Object.keys(familias.data).map((familia, index) =>
                                        <option key={index} >
                                            {familia}
                                        </option>
                                        )}
                                        {/*familias end */}
                                    </datalist>
                                }
                                <input type="search" list="datalistOptions2" className="form-control form-control-sm"  placeholder="Carroceria"
                                defaultValue={carroceria_auto_buscada} onChange= {(event) => {setCarroceriaAutoBuscada(event.target.value.toUpperCase())}}
                                />
                                <datalist id="datalistOptions2">
                                    {/*familias start */}
                                    {carrocerias_auto.map( (carro,index)=> 
                                        <option key={index}>{carro.id_modelo}</option>
                                    )}
                                    {/*familias end */}
                                </datalist>

                                <input type="search" list="datalistOptions3" className="form-control form-control-sm" placeholder="Modelo"
                                defaultValue={modelo_auto_buscado} onChange= {(event) => {setModeloAutoBuscado(event.target.value)}}
                                />
                                <datalist id="datalistOptions3">
                                    {/*familias start */}
                                    {modelos_auto.map( (modelo,index)=> 
                                        <option key={index}>{modelo.id_tipo}</option>
                                    )}
                                    {/*familias end */}
                                </datalist>

                                <input type="search" list="datalistOptions4" className="form-control form-control-sm" placeholder="Motor"
                                defaultValue={motor_buscado} onChange= {(event) => {setMotorBuscado(event.target.value.toUpperCase())}}
                                />
                                <datalist id="datalistOptions4">
                                    {/*familias start */}
                                    {motores.map( (motor,index)=> 
                                        <option key={index}>{motor.id_motor}</option>
                                    )}
                                    {/*familias end */}
                                </datalist>
                            
                                <input type="text" className="form-control form-control-sm" placeholder="Año de tu auto"
                                    defaultValue={anno_buscado} onChange= {(event) => {setAnnoBuscado(event.target.value.substr(-2))}}
                                />

                                <div className="form-check" style={{fontSize:'0.8rem'}}>
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        Solo Repuestos Nuevos
                                    </label>
                                </div>

                                <button type="button" className="btn btn-sm botonRojo text-white"
                                onClick={() => {restablecerFiltros()}}
                                >
                                    Restablecer Filtros
                                </button>
                            </div>
                        </MDBAccordionItem>
                    </MDBAccordion>
                </div>
                    
                {panelInicial ?
                    <div className="col-sm-6 col-md-10">
                        <div className='nav shadow-sm ' 
                            style={{margin:'0px 0px 18px 0px', height:'60px', paddingTop:'10px', paddingBottom:'15px', backgroundColor:'white'}}
                        >
                            <form className="d-flex nav-item">
                                <input className="form-control me-2" type="search" placeholder="Buscar" aria-label="Search" 
                                    onChange= {(event) => {setCategoriaBuscada(event.target.value)}}
                                />
                            </form>
                        </div>
                            {/*familias start */}
                            {familias_grilla.length === 0 ?
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-primary" role="status" style={{width:'3rem',height:'3rem'}} >
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                    :
                                <div className={'row row-cols-2'}>
                                    {familias_grilla.map((elem,index) => {
                                        return (
                                            <div className='col text-center' key={index}>
                                                <a onClick= {() => {onClickProductoVerMas(elem)}} 
                                                >
                                                    <div className="card cartaMovil mx-auto"  
                                                    >
                                                        <img src={elem[1][0].imagen_24lemans} 
                                                            className='imagenCartaMovil'
                                                            alt='Encuentra componentes, repuestos originales y alternativos para autos de alta gama, trabajamos con marcas de calidad como Zimmerman, AL-KO, ALCO, HEPU, ElRing'
                                                        />
                                                        <div className="card-body text-center">
                                                            <p className="card-title">{elem[1][0].id_familia}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })}
                                </div>                                                                  
                            }
                    </div>
                    :
                    <div className="col-sm-6 col-md-10">
                        <button onClick= {() => {restablecerFiltros();setPanelInicial(true);}}
                            className={'btn btn-sm botonRojo text-white'}
                            style={{margin:'0px 0px 20px 18px'}}
                        >
                            Ver Categorias
                        </button>
                        <div className='row row-cols-2'>
                        {productos.loading === true ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-primary" role="status" style={{width:'3rem',height:'3rem'}} >
                                <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            (productos_grilla).map((elem,index) => {
                                return (
                                    <div className='col text-center' key={index}>
                                        <Link style={{color:"black"}}
                                            to={{
                                                pathname: "/producto/"+elem[1][0].parte+"/"+elem[1][0].id_marca+"/"+elem[1][0].clase,
                                            }}
                                        >
                                        <div className="card cartaMovil mx-auto">
                                            <img src={elem[1][0].imagen_24lemans} 
                                                alt='Encuentra componentes, repuestos originales y alternativos para autos de alta gama, trabajamos con marcas de calidad como Zimmerman, AL-KO, ALCO, HEPU, ElRing'
                                                className='imagenCartaMovil'
                                            />
                                            <div className="card-body text-center">
                                                <div>
                                                <p className="card-title" style={{marginBottom:'1px',fontSize:'0.8rem'}}>{elem[1][0].parte}</p>
                                                <p className='card-text' style={{fontSize:'0.7rem'}}>{elem[1][0].id_marca} {elem[1][0].clase}</p>
                                                </div>
                                            </div>
                                        </div>
                                        </Link >
                                    </div>
                                )
                            })
                        }
                    </div>
                    </div>
                }
            </div>
            <div className="col-12">
                {panelInicial ? 
                    <ul className="pagination justify-content-center mt-1">
                        {pagesFamilia.map((page) => (
                            <li
                            key={page.pageIndex}
                            className={
                                currentPageIndex === page.pageIndex
                                ? "page-item active"
                                : "page-item"
                            }
                            onClick={() => {
                                onPageFamiliaIndexClicked(page.pageIndex);
                            }}
                            >
                            <a
                                className="page-link "
                                href="/#"
                                onClick={(event) => {
                                event.preventDefault();
                                }}
                            >
                                {page.pageIndex + 1}
                            </a>
                            </li>
                        ))}
                    </ul>
                    :
                    <ul className="pagination justify-content-center mt-1">
                        {pagesProductos.map((page) => (
                            <li
                            key={page.pageIndex}
                            className={
                                currentPageIndex === page.pageIndex
                                ? "page-item active"
                                : "page-item"
                            }
                            onClick={() => {
                                onPageProductoIndexClicked(page.pageIndex);
                            }}
                            >
                            <a
                                className="page-link"
                                href="/#"
                                onClick={(event) => {
                                event.preventDefault();
                                }}
                            >
                                {page.pageIndex + 1}
                            </a>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </div>
        <Footer/>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    familias: state.familias,
    productos: state.productos
});
  
const mapDispatchToProps = {
    dispatchFetchFamilias: actions.fetchFamilias,
    dispatchFetchProductosFiltrados: actions.fetchProductosFiltrados
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoMovil);
