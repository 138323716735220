import React from 'react';
import Banner1 from '../../assets/BannerPrincipal-rec.png';
import Banner1Mov from '../../assets/Banner1-mov.png';
import Banner2 from '../../assets/Banner2-rec.png';
import Banner2Mov from '../../assets/Banner2-mov.png';
import Banner3 from '../../assets/Banner3-rec.png';
import Banner3Mov from '../../assets/Banner3-mov.png';
import BuscarPieza from '../Inicio/BuscarPieza';
import {useMediaQuery} from 'react-responsive';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './sliders.css';

export default function App() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      }

    const estilosVistaGrande = {
        banner:{
            position:'relative',
        },
        imagenTexto: {
            position:'absolute',
            left:'20px', 
            top:'120px'
        }
    }
    const estilosVistaCelular = {
        banner:{
            position:'relative',
            height:'230px',
        },
        imagenTexto: {
            height:'25%',
            position:'absolute',
            left:'20px', 
            top:'50px'
        }
    }

    return (
      <React.Fragment>
        <div style={{position:'relative'}}>
        <Carousel
          partialVisible={false}
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          keyBoardControl={true}
          transitionDuration={500}
          dotListClass="custom-dot-list-style"
          centerMode={false}
          renderButtonGroupOutside={false} 
          arrows = {true}
          >
            <div>
                <img src={isTabletOrMobile?Banner1Mov: Banner1} alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.' className='image-fluid' style={isTabletOrMobile?estilosVistaCelular.banner:estilosVistaGrande.banner}/>
            </div>

            <div>
                <img src={isTabletOrMobile?Banner2Mov:Banner2} alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.' className='image-fluid' style={isTabletOrMobile?estilosVistaCelular.banner:estilosVistaGrande.banner}/>
            </div>

            <div>
                <img src={isTabletOrMobile?Banner3Mov:Banner3} alt='Somos 24 Bremse Chile, contamos con repuestos automotrices para marcas de alta gama, como BMW, Mercedes Benz, Volvo, Audi, Porsche, Land Rover, entre otros.' className='image-fluid' style={isTabletOrMobile?estilosVistaCelular.banner:estilosVistaGrande.banner}/>
            </div>
        </Carousel>
        <BuscarPieza/>
        </div>
      </React.Fragment>

  );
}