import React, {useEffect} from 'react';
import { connect } from "react-redux";
import actions from "../../actions";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardImage, MDBRipple } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import {useMediaQuery} from 'react-responsive';

function Posts({blogPosts,dispatchPosts}) {
    useEffect(() => {
        dispatchPosts();
    }, [dispatchPosts]);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

    const CustomRight = ({ onClick }) => (
        <a>
        <i className="fa fa-arrow-right flecha-slider" onClick={onClick} 
          style={{position:'absolute', right:'10px', fontSize:'2rem'}}
        />
        </a>
      );
      const CustomLeft = ({ onClick }) => (
        <a>
        <i className="fa fa-arrow-left flecha-slider" onClick={onClick} 
          style={{position:'absolute', left:'10px', fontSize:'2rem'}} 
        />
        </a>
    );

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }

    let renderSwitch = (param) =>{
        switch(param) {
            case '1':
                return 'ENE';
            case '2':
                return 'FEB';
            case '3':
                return 'MAR';
            case '4':
                return 'ABR';
            case '5':
                return 'MAY';
            case '6':
                return 'JUN';
            case '7':
                return 'JUL';
            case '8':
                return 'AGO';
            case '9':
                return 'SEP';
            case '10':
                return 'OCT';
            case '11':
                return 'NOV';
            case '12':
                return 'DIC';
            default:
        };
    }

    return (
        <React.Fragment>
            {isTabletOrMobile?<br/>:void 0}
            <div className='container' style={{paddingTop:'30px',paddingBottom:'30px'}}>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                transitionDuration={500}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px" 
                centerMode={false}
                renderButtonGroupOutside={false} 
                arrows = {true}
                customRightArrow={<CustomRight />}
                customLeftArrow={<CustomLeft />} 
                renderButtonGroupOutside={false}
            >
                {blogPosts.data.map((elem)=>{
                    return(
                        <Link style={{color:"black",marginRight:'5px'}} key={elem.codigo}
                            to={{
                                pathname: "/post/"+elem.codigo,
                            }}
                        >
                            <MDBCard style={isTabletOrMobile?{ maxWidth: '12rem',margin:'auto'}:{ maxWidth: '22rem',marginRight:'40px'}}>
                            <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay' style={{backgroundColor:'black'}}>
                                <MDBCardImage src={elem.imagen} fluid alt='...' style={{maxHeight:'150px'}} className='mx-auto d-block'/>
                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                            </MDBRipple>
                            <MDBCardBody>
                                <MDBCardTitle>
                                    <div className={isTabletOrMobile?'row row-cols-1':'row'}>
                                        <div className='col-3'>
                                            <div className='text-center' style={{backgroundColor:'#2c2c2c', color:'white', width:'50px'}}>
                                                {elem.fecha.split('-')[0]}
                                                <br/>
                                                {renderSwitch(elem.fecha.split('-')[1])}
                                            </div>
                                        </div>
                                        <div className='col'>
                                            {elem.titulo}
                                        </div>
                                    </div>
                                </MDBCardTitle>
                            </MDBCardBody>
                            </MDBCard>
                        </Link>
                    )
                })}
            </Carousel>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    post: state.post,
    blogPosts: state.blogposts 
});
  
const mapDispatchToProps = {
    dispatchFetchPost: actions.fetchPost,
    dispatchPosts: actions.fetchBlogPosts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts);