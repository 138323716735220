import * as actionTypes from "../constants/action-types";
import axios from "axios";

import {
  URL_FETCH_AUTOS,
  URL_FETCH_AUTOS_POR_PRODUCTO
} from "../config";

export const fetchAutos = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_AUTOS});
  try
  {
    var response = await axios.get(`${URL_FETCH_AUTOS}/${props}`); 

    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_AUTOS_SUCCES,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_AUTOS_ERROR,
      payload: err
    });
  }
};

export const fetchAutosxProducto = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_AUTOS_X_PRODUCTO});
  try
  {
    var response = await axios.get(`${URL_FETCH_AUTOS_POR_PRODUCTO}/${props.parte}/${props.marca}/${props.clase}`); 

    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_AUTOS_X_PRODUCTO_SUCCES,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_AUTOS_X_PRODUCTO_ERROR,
      payload: err
    });
  }
};
