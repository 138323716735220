import React from 'react';
import {useMediaQuery} from 'react-responsive';
import HeaderDesktop from './HeaderDesktop';
import HeaderMovil from './HeaderMovil';

export default function Header() {
    const isBigScreen = useMediaQuery({ minWidth: 1824 })
    const isDesktopOrLaptopSmall = useMediaQuery({ maxWidth: 1510 })
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    return (
        <React.Fragment>    
         {  isBigScreen ? <HeaderDesktop /> :
            isTabletOrMobile ? <HeaderMovil /> :
            isDesktopOrLaptopSmall ? <HeaderDesktop />  : 
            <HeaderDesktop /> 
         }
        </React.Fragment>
    )
}