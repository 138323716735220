import * as actionTypes from "../constants/action-types";
import axios from "axios";

import {
  URL_FETCH_BLOG_POSTS,
  URL_POST_POST
} from "../config";

export const fetchBlogPosts = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_BLOG_POSTS});
  try
  {
    var response = await axios.get(`${URL_FETCH_BLOG_POSTS}`);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_BLOG_POSTS_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_BLOG_POSTS_ERROR,
      payload: err
    });
  }
};

export const fetchPost = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_POST});
  try
  {
    var response = await axios.get(`${URL_POST_POST}/${props.codigo}`);
    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_POST_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_POST_ERROR,
      payload: err
    });
  }
};