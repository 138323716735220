import React, {useEffect} from 'react';
import Footer from '../Footer/Footer';
import { connect } from "react-redux";
import actions from "../../actions";
import { useParams } from "react-router-dom";
import LineasDer from '../../assets/LineasDerecha.png';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardImage, MDBRipple } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import SliderPosts from '../Sliders/SliderPosts';

function Post({post,dispatchFetchPost,blogPosts,dispatchPosts}) {
    let parametros = useParams();

    useEffect(() => {
        dispatchFetchPost(parametros);
        dispatchPosts();
    }, [parametros,dispatchFetchPost,dispatchPosts]);

    let renderSwitch = (param) =>{
        switch(param) {
            case '1':
                return 'ENE';
            case '2':
                return 'FEB';
            case '3':
                return 'MAR';
            case '4':
                return 'ABR';
            case '5':
                return 'MAY';
            case '6':
                return 'JUN';
            case '7':
                return 'JUL';
            case '8':
                return 'AGO';
            case '9':
                return 'SEP';
            case '10':
                return 'OCT';
            case '11':
                return 'NOV';
            case '12':
                return 'DIC';
            default:
        };
    }

    return (
        <React.Fragment>
        {post.data.loading || post.data[0]===undefined ? 
            void 0 
            :
            <div>
                <div className='container-xxl pt-4'>
                    <h3>{post.data[0].titulo}</h3>
                </div>
                <div className='container-xxl shadow mb-6 pt-4 pb-4'  style={{backgroundColor:'white'}}>
                    <img src={post.data[0].imagen} className="img-fluid mb-2 mx-auto d-block" alt='Blog 24 Bremse Chile: Venta y Distribución de Repuestos para autos de gama alta' style={{maxHeight:'260px'}}/>
                    <div className='container p-5'>
                        {post.data[0].texto}
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <img src={post.data[0].imagen2} className="img-fluid mb-2 mx-auto d-block" alt='Blog 24 Bremse Chile: Venta y Distribución de Repuestos para autos de gama alta' style={{maxHeight:'260px'}}/>
                            <div className='container'>
                                {post.data[0].texto2}
                            </div>
                        </div>
                        <div className='col'>
                            <img src={post.data[0].imagen3} className="img-fluid mb-2 mx-auto d-block" alt='Blog 24 Bremse Chile: Venta y Distribución de Repuestos para autos de gama alta' style={{maxHeight:'260px'}}/>
                            <div className='container'>
                                {post.data[0].texto3}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        <div className='container'><h4 >Mas Publicaciones</h4></div>
        <div className='container pt-4 mb-5 shadow shadow-lg bg-white'>
            <SliderPosts />
        </div>
        <Footer/> 
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    post: state.post,
    blogPosts: state.blogposts 
});
  
const mapDispatchToProps = {
    dispatchFetchPost: actions.fetchPost,
    dispatchPosts: actions.fetchBlogPosts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);