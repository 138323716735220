import * as actionTypes from "../constants/action-types";
import axios from "axios";

import {
  URL_FETCH_PRODUCTOS_DESTACADOS,
  URL_FETCH_PRODUCTOS,
  URL_FETCH_PRODUCTOS_FILTRADOS,
  URL_FETCH_PRODUCTO
} from "../config";
export const fetchProductos = () => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_PRODUCTOS});
  try
  {
    var response = await axios.get(`${URL_FETCH_PRODUCTOS_DESTACADOS}`);

    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PRODUCTOS_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PRODUCTOS_ERROR,
      payload: err
    });
  }
};


export const fetchProductosFamilia = (props) => async (dispatch) => {
  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_PRODUCTOS_FAMILIA});
  try
  {
    var response = await axios.get(`${URL_FETCH_PRODUCTOS}/${props}`);

    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PRODUCTOS_FAMILIA_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PRODUCTOS_FAMILIA_ERROR,
      payload: err
    });
  }
};


export const fetchProductosFiltrados = (props) => async (dispatch) => {
  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  if(props.id_familia ==="" && props.id_tipo ==="" && props.id_modelo ==="" && props.id_motor ==="" && props.anno ==="" && props.marca ===""){
    void 0
  }else {
    dispatch({ type: actionTypes.FETCH_PRODUCTOS_FILTRADOS});
    try
    {
      var response = await axios.post(`${URL_FETCH_PRODUCTOS_FILTRADOS}`,
        {
          familia:props.id_familia,
          tipo:props.id_tipo,
          modelo:props.id_modelo,
          motor:props.id_motor,
          anno:props.anno,
          marca:props.marca
        }
      );
      //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
      dispatch({
        type: actionTypes.FETCH_PRODUCTOS_FILTRADOS_SUCCESS,
        payload: response.data
      });
    }
    catch(err)
    {
      //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
      dispatch({
        type: actionTypes.FETCH_PRODUCTOS_FILTRADOS_ERROR,
        payload: err
      });
    }
  }
};

export const fetchProducto = (props) => async (dispatch) => {

  //dispatch response data to FETCH_PRODUCTOS action; it will be received by the reducer
  dispatch({ type: actionTypes.FETCH_PRODUCTO});
  try
  {
    var response = await axios.get(`${URL_FETCH_PRODUCTO}/${props.parte}/${props.marca}/${props.clase}`);

    //dispatch response data to FETCH_TASKS_SUCCESS action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PRODUCTO_SUCCESS,
      payload: response.data
    });
  }
  catch(err)
  {
    //dispatch error to FETCH_TASKS_ERROR action; it will be received by the reducer
    dispatch({
      type: actionTypes.FETCH_PRODUCTO_ERROR,
      payload: err
    });
  }
};
