import React from 'react';
import Header from './components/Header/Header';
import Navbar from "./components/Navbar/Navbar";
import NoMatchPage from "./components/NoMatchPage/NoMatchPage";
import Inicio from "./components/Inicio/Inicio"
import { HashRouter } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Provider } from "react-redux";
import store from "./store";
import "./styles/home.css";
import Catalogo from './components/Catalogo/Catalogo';
import Producto from './components/Producto/Producto';
import Cotizacion from './components/Cotizaciones/Cotizacion';
import Blog from './components/Blog/Blog';
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import Post from './components/Blog/Post';
import {useMediaQuery} from 'react-responsive';
import GoogleConsole from './components/TusClicks/googleConsole';

function App() {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  return (
        <HashRouter>
        <Header />
        <div style={{position:'sticky', top:'0px',backgroundColor:'#f10c0c', zIndex:'2'}}>
        <Navbar />
        </div>
        <Provider store={store}>
        <Switch>
        <Route path="/" exact={true} component={Inicio} />
        <Route path="/catalogo" exact={true} component={Catalogo} />
        <Route path="/producto/:parte/:marca/:clase" component={Producto} />
        <Route path="/cotizaciones" component={Cotizacion} />
        <Route path="/blog" component={Blog} />
        <Route path="/post/:codigo" component={Post} /> 
        <Route path="/google7ae67dd7ebbf8cdc.html" component={GoogleConsole} /> 
        <Route path="*" component={NoMatchPage} />
        </Switch>
        </Provider>
        {isTabletOrMobile?void 0 :
        <WhatsAppWidget 
          phoneNumber='56976674205' 
          message='Hola! 👋🏼 Como te podemos ayudar?'
          companyName='24Lemans Chile'
          sendButton='Enviar'
          textReplyTime='Respondemos lo antes posible'
        />
        }
        </HashRouter>
  )
}

export default App;
