import * as actionTypes from "../constants/action-types";

//reducer is a function that receives an action and returns new state.
let initialState = { data: [], loading: true, error: "" };

export const productosReducer = (state = initialState, action) => {
  switch (action.type)
  {
    case actionTypes.FETCH_PRODUCTOS:
      return { data: [], loading: true, error: ""};

    case actionTypes.FETCH_PRODUCTOS_SUCCESS:
      return { data: action.payload, loading: false, error: ""};
  
    case actionTypes.FETCH_PRODUCTOS_ERROR:
      return { data: state, loading: true, error: action.payload};

      case actionTypes.FETCH_PRODUCTOS_FILTRADOS:
      return { data: [], loading: true, error: ""};

    case actionTypes.FETCH_PRODUCTOS_FILTRADOS_SUCCESS:
      return { data: action.payload, loading: false, error: ""};
  
    case actionTypes.FETCH_PRODUCTOS_FILTRADOS_ERROR:
      return { data: state, loading: true, error: action.payload};

    case actionTypes.FETCH_PRODUCTOS_FAMILIA:
      return { data: [], loading: true, error: ""};

    case actionTypes.FETCH_PRODUCTOS_FAMILIA_SUCCESS:
      return { data: action.payload, loading: false, error: ""};
  
    case actionTypes.FETCH_PRODUCTOS_FAMILIA_ERROR:
      return { data: state, loading: true, error: action.payload};
    
    case actionTypes.FETCH_PRODUCTO:
      return { data: [], loading: true, error: ""};
  
    case actionTypes.FETCH_PRODUCTO_SUCCESS:
      return { data: action.payload, loading: false, error: ""};
  
    case actionTypes.FETCH_PRODUCTO_ERROR:
      return { data: state, loading: false, error: action.payload};

    default:
      return state;
  }
};
