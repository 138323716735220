import React from 'react';
import Footer from '../Footer/Footer';
import {useMediaQuery} from 'react-responsive';
import BlogMovil from './BlogMovil';
import BlogPC from './BlogPC';

export default function Blog() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    
    return (
        <React.Fragment>
            {isTabletOrMobile?<BlogMovil/>:<BlogPC/>}
            <Footer/>
        </React.Fragment>
    )
}